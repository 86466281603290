.btnInfo-exit, .btnInfo-download, .btnInfo-left, .btnInfo-right{
    cursor: pointer;
}

.info-mobile {
  min-height: 100vh; 
  width: 100%; 
  margin: 0; 
  padding: 0; 
  overflow-x: hidden; 
}
.container, .row, .col {
  padding: 0;
  margin: 0;
  max-width: 100%; 
}

.btnInfo-exit{
  width: 40px;
    height: 40px;
}
.btnInfo-download{
    width: 40px;
    height: 40px;
}

.btnInfo-exit, .Image-profile, .btnInfo-download, .btnInfo-left, .btnInfo-right {
  margin: 0;
  padding: 0;
}


.custom-row, .col-auto, .col {
  padding: 0;
  margin: 0;
}
 
.row>* {
  padding-right: 0 !important;
}
.row{
  --bs-gutter-x: 0 !important;
}
.employee-name,.talent-subtitle-mobile,.lang-subtitle-mobile{
  font-family: Telefonica Sans;
}

.employee-job,.employee-years,
.employee-years-role,.employee-location,
.employee-code,.language-container,.talent-table-mobile{
  font-family: Telefonica_Sans_Light !important;
}

.logo-telefo-mobile{
  margin-top: 1.05rem !important;
  width: 14rem !important;
}


