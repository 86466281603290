.articleItem {
  padding: 0.938em 1.375em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2em;
  border: 1px solid #d1d5e4;
}

.relatedArticles__container {
  margin-bottom: 10px;
  padding: 0 22px;
  width: 100%;
}

.articleItem.expanded {
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
}

.relatedContentBox.expanded {
  flex-direction: column;
  align-items: flex-start;
}

.articleItem__firstSection {
  cursor: pointer;
}

.articleItem__title {
  color: var(--color-blue);
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 0 !important;
}

.articleItem__plus {
  display: block;
}

.articleItem__plus.expanded {
  display: none;
}

.articleItem__minus {
  display: none;
}

.articleItem__minus.expanded {
  display: block;
}

.articleItem__firstSection {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.articleItem__firstSection.expanded {
  border-bottom: 1px solid #d1d5e4;
  padding: 0.938em 1.375em;
}

.answerBox {
  padding: 18px 24px;
  font-size: 0.875em;
  text-align: justify;
  line-height: 1.5;
}
.answerBox ul {
  padding-left: 1.125em;
}

.answerBox img {
  max-width: 100%;
  padding: 18px 24px;
}

.answerBox a {
  color: var(--color-blue);
}

.contactUsContainer {
  display: flex;
  flex-direction: row;
  padding: 10px 24px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #d1d5e4;
  border-right: 1px solid #d1d5e4;
  border-left: 1px solid #d1d5e4;
}

.contactUsContainer__text {
  color: #414b61;
  font-size: 0.875em;
  font-weight: 600;
}

.contactUsContainer__button {
  border-radius: 30.5px;
  border: 1px solid var(--color-blue);
  background-color: var(--color-white);
  font-size: 0.875em;
  color: var(--color-blue);
  padding: 10px;
  cursor: pointer;
}

.footerBox {
  display: flex;
  flex-direction: row;
  padding: 18px 24px;
  width: 100%;
  justify-content: space-between;
  background-color: #f2f4ff;
  border-radius: 0 0 2em 2em;
}

.articleList--article__useful {
  color: #414b61;
  font-size: 0.875em;
  font-weight: 600;
}

.iconBox {
  display: flex;
  gap: 2em;
}

.subcategories__articleList--article__like {
  cursor: pointer;
}

.subcategories__articleList--article__dislike {
  transform: rotate(180deg);
  cursor: pointer;
}
.relatedContent__container {
  width: 100%;
  padding: 5px 0;
}

/* Artículos relacionados */

.relatedContentBox {
  padding: 18px 22px;
}

.relatedArticleItem__containerTitle {
  font-size: 1.125em;
  color: var(--color-blue);
  margin-top: 1em;
}

.relatedContentBox__textContainer {
  border-radius: 22px;
  border: 1px solid #d1d5e4;
  padding: 0.625em 1.875em;
}

.relatedArticleItem__title {
  color: var(--color-blue);
  font-size: 1em;
  font-weight: 600;
  line-height: 150%;
}

.relatedArticleItem__text img {
  max-width: 100%;
  padding: 18px 24px;
}

/* dislike pop up  */

.dislikePopup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.dislikePopup-content {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 680px;
}
.failurePopup {
  width: 400px;
}

.dislikePopup__sectionTitle {
  display: flex;
  color: var(--color-black);
  border-radius: 10px 10px 0 0;
}

.dislikePopup__sectionTitle--closeIcon {
  height: 1.5em;
  cursor: pointer;
}
.dislikePopup__sectionTitle--title {
  font-size: 35px;
}
.spacing {
  margin-bottom: 0.8em;
}

.dislikePopup__sectionText--text {
  width: 100%;
  padding: 1em;
  border: none;
  border-radius: 10px;
  background-color: #f6f6f6;
  height: 170px;
  outline: none;
  resize: none;
}

.dislikePopup__sectionText--text::placeholder {
  color: #414b61;
  font-size: 1em;
  font-family: Telefonica Sans;
}

.dislikePopup__sectionButtons {
  display: flex;
  justify-content: left;
  margin-top: 2em;
}

.dislikePopup__sectionButtons--button {
  border: none;
  padding: 1em 2em;
  border-radius: 100px;
  border: 1px solid #06f;
  opacity: 0.5;
  background: #3363f0;
  color: var(--color-white);
}
.contactMeSectionButton {
  display: flex;
  justify-content: right;
  margin-top: 2em;
}

.contactMePopup--button {
  border: none;
  padding: 0.8em 1em;
  border-radius: 100px;
  border: 1px solid #06f;
  background: #3363f0;
  color: var(--color-white);
  cursor: pointer;
}

.failureButton {
  font-size: 0.875em;
}

.dislikePopup__sectionButtons--button.enabled {
  opacity: 1;
  cursor: pointer;
}

.linkButtonContainer {
  padding: 18px 24px;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.linkButton {
  text-decoration: none;
  border: 1px solid var(--color-blue);
  padding: 0.938em 1.375em;
  color: white;
  background-color: var(--color-blue);
  border-radius: 20px;
}

@media (max-width: 768px) {
  .dislikePopup-content {
    width: 400px;
  }
  .dislikePopup__sectionTitle--title {
    font-size: 1.5em;
  }
  .dislikePopup__sectionTitle {
    margin-bottom: 1em;
  }
  .iconBox {
    gap: 1em;
  }
}
