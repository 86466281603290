.about {
  margin-top: 5em;
}

.about__breadcrumb {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 2.5em;
}

.about h4 {
  color: #6e7894;
  font-size: 0.875em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25em;
}

.cirle-icon {
  width: 0.625rem;
  height: 0.625rem;
  color: var(--color-blue);
}

.about__content {
  display: flex;
  flex-direction: column;
}

.about__description {
  margin-top: 2.5em;
  color: var(--color-black);
  font-size: 1em;
  font-style: normal;
  line-height: 1.5rem;
}

.about__link {
  color: var(--color-black);
  font-size: 1em;
  font-style: normal;
  line-height: 1.5rem;
  margin-top: 2.5em;
}
