.autocomplete-container {
  position: relative;
}

.autocomplete-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ccc;
  z-index: 10;
  max-height: 150px;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 0;
}

.autocomplete-suggestion {
  padding: 8px;
  cursor: pointer;
}

.autocomplete-suggestion:hover {
  background: #f0f0f0;
}
