.rolesPage {
  padding: 5em 0;
}

.rolesPage__breadcrumb {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 2.5em;
}

.rolesPage__breadcrumb--title {
  color: #6e7894;
  font-size: 0.875em;
  line-height: 1.25em;
  font-weight: 700;
  text-decoration: none;
}

.rolesPage__breadcrumb--text {
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  color: #6e7894;
  font-size: 0.875em;
}

.rolesPage__intro {
  display: flex;
  gap: 2.5em;
}

.rolesPage__intro--info {
  display: flex;
  flex-direction: column;
  gap: 0.625em;
}

.intro__info--title {
  font-size: 1.125em;
  color: var(--color-blue);
}

.intro__info--text {
  color: #676d83;
  font-size: 1em;
}

.rolesPage__intro--search {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.rolesPage__searcherSection {
  display: flex;
  background-color: #f8f8f8;
  padding: 0.625em 1.25em;
  gap: 0.625em;
  border-radius: 52px;
  cursor: pointer;
  width: 20em;
}

.rolesPage__searcher {
  width: 1.25em;
  height: 1.25em;
}

.rolesPage__searcherInput {
  border: none;
  background-color: #f8f8f8;
  outline: none;
}

.rolesPage__searcherInput::placeholder {
  color: #676d83;
  font-size: 0.8753em;
}

.rolesPage__button {
  width: 12.3em;
  background-color: var(--color-blue);
  color: var(--color-white);
  border: none;
  border-radius: 100px;
  padding: 0.625em 1.25em;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}

.rolesPage__content a {
  text-decoration: none;
}

.rolesPage__title {
  color: var(--color-blue);
  /* font-size: 1.688em; */
  font-size: 1.375em;
  margin-bottom: 2.5em;
  margin-top: 1.25em;
}

.rolesSubcategories__all {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 2.5em 3em;
  /* 0.938em; */
}

.rolesSubcategories a {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 2.5em 3em;
  /* 0.938em; */
  cursor: pointer;
}

.rolesSubcategories {
  height: 100%;
  width: 14em;
  border-radius: 8px;
  border: 1px solid #d1d5e4;
  flex: 1;
}

.rolesSubcategories__imageContent {
  height: 9.5em;
  width: 100%;
}

.rolesSubcategories__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.rolesSubcategories__textContent {
  padding: 1.5em;
}

.rolesSubcategories__groupTitle {
  color: #031a34;
  font-size: 1.25em;
  line-height: 140%;
}

.rolesSubcategories__groupTitle:hover {
  color: var(--color-blue);
}

.rolesSubcategories__description {
  padding-bottom: 0.5em;
  height: 100%;
}

.rolesSubcategories__list a {
  list-style: none;
  color: #6e7894;
  font-size: 1em;
  line-height: 150%;
  cursor: pointer;
}

.rolesSubcategories__list a:hover {
  text-decoration-line: underline;
}

@media (max-width: 1500px) {
  .rolesSubcategories__all {
    gap: 2.5em 1.5em;
  }
}

@media (max-width: 1460px) {
  .rolesSubcategories__all {
    gap: 2.5em 1em;
  }
}

@media (max-width: 1200px) {
  .rolesSubcategories {
    width: 15em;
  }

  .rolesSubcategories__all {
    gap: 2.5em 3.5em;
  }
}

@media (max-width: 900px) {
  .rolesSubcategories {
    width: 14em;
  }

  .rolesSubcategories__all {
    gap: 2.5em 0.938em;
  }
}

@media (max-width: 700px) {
  .rolesPage__intro {
    flex-direction: column-reverse;
  }

  .rolesSubcategories {
    width: 10.5em;
  }
}

@media (max-width: 600px) {
  .rolesSubcategories__all {
    gap: 2.5em 3.5em;
  }

  .rolesSubcategories {
    width: 12em;
  }
}

@media (max-width: 500px) {
  .rolesPage__searcherSection {
    width: 15em;
  }

  .rolesSubcategories__all {
    gap: 2.5em 0.938em;
  }

  .rolesSubcategories__list {
    display: none;
  }
}

@media (max-width: 400px) {
  .rolesSubcategories {
    width: 9em;
  }

  .rolesSubcategories__all {
    gap: 2.5em 0.5em;
  }
}