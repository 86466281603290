
 .svg-Style-mobile-blue,
 .svg-Style-cluster-mobile-blue {
   border-radius: 50px; 
   Fill: #007bff ; 
   height: 45px;
   width: 140px;
   margin-top: 15px;
   
 }
.svg-container-mobile-blue {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.position-svg-mobile-blue {
  font-size: 14px;
  text-anchor: middle;
  dominant-baseline: middle;
}

.circles-container-mobile-blue {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-mobile-blue {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
  font-size: 14px;
  font-weight: bold;
}

.arrow-circle-blue {
  width: 30px !important;
}
.arrow-position-initial-blue{
  transform: translate(27px, 7px);
}

@media (max-width: 360px) and (max-height: 740px){
  .cluster-mobile-blue .circle-mobile-blue {
    width: 30px !important;
    height: 30px !important;
    border-radius: 50%;
    background-color: #007bff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px;
    font-size: 14px;
    font-weight: bold;
  }
}

/* Media query combinada para iPhone 12 Pro, iPhone 11 Pro, y iPhone XR */
@media (max-width: 375px) and (max-height: 667px),
       (max-width: 390px) and (max-height: 844px),
       (max-width: 412px) and (max-height: 915px),
       (max-width: 414px) and (max-height: 896px)  {

  .svg-Style-mobile-blue,
  .svg-Style-cluster-mobile-blue {
    border-radius: 50px; 
    fill: #007bff; 
    height: 40px;
    width: 120px;
    margin-top: 15px;
  }

  .svg-container-mobile-blue {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
  }
  
  .arrow-position-initial-blue {
    transform: translate(10px, 7px); 
  }

  .position-svg-mobile-blue {
    font-size: 10px;
    text-anchor: middle;
    dominant-baseline: middle;
  }

  .circles-container-mobile-blue {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cluster-mobile-blue .circle-mobile-blue {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #007bff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2px;
    font-size: 14px;
    font-weight: bold;
  }

  .arrow-circle-blue {
    width: 30px !important;
  }
}
