/* Estilos generales */
.historialTickets-main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.historialTickets-list-container {
  display: flex;
}

.historialTickets-filter-section {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: 1px solid #ccc;
}

.historialTickets-table-container {
  width: 100%;

  /*  margin: 20px auto; */
  margin-top: 10px;
  width: 100%;
  display: flex;
  gap: 3em;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-size: 14px;
}
.tickets-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-size: 12px;
}

.tickets-table th,
.tickets-table td {
  border-bottom: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.tickets-table th {
  background-color: #f2f2f2;
}

.tickets-table tr:hover {
  background-color: #f1f1f1;
}
.tickets-table td h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 0;
  display: block;
  text-align: left;
}
.tickets-table td p {
  margin: 0;
  padding: 0;
  display: block;
  text-align: left;
}

.historialTickets-especialTD {
  background-color: #f9f9f9;
  font-weight: 700;
  grid-column: span 9;
  padding: 10px;
  text-align: left;
  width: 100%;
  margin-bottom: 70px;
}
.historialTickets-margin-row .ticketsTable-td {
  height: 20px;
  background-color: transparent;
}

/* Prioridad (Pr) */
.historialTickets-priority-high {
  background-color: red;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.historialTickets-priority-medium {
  background-color: orange;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

/* Buscador */
.historialTickets-search-input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
}
@media (max-width: 700px) {
  .historialTickets-list-container {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .historialTickets-list-container {
    width: 100%;
  }
}
