
.secondModalStyle {
  border: none;
  height: 100vh;
  max-height: 100vh;
}
/* .informationEmployee {
  height: 100vh;
  max-height: 100vh;
} */

/* confirm-alert-custom.css */
.react-confirm-alert-overlay {
  background-color: rgba(0, 0, 0, 0.5); 
}

.react-confirm-alert-body {
  background-color: #ffffff; 
  border-radius: 8px; 
  padding: 20px; 
  max-width: 450px; 
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); 
  color: #333333;
  font-family: Telefonica_Sans_Light;
  text-align: center; 
}

.react-confirm-alert-button-group {
  display: flex;
  justify-content: center;
  gap: 20px; 
  margin-top: 20px; 
}

.react-confirm-alert-button-group button {
  padding: 10px 30px; 
  border: none; 
  border-radius: 20px; 
  cursor: pointer; 
  font-weight: bold; 
  color: #ffffff;
  font-size: 16px; 
  background-color: #007BFF;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
  transition: background-color 0.3s; 
}

.react-confirm-alert-button-group button:hover {
  background-color: #0056b3; 
}


.react-confirm-alert-button-group button:nth-child(2) {
  background-color: #f8f9fa;
  color: #333333; 
}

.react-confirm-alert-button-group button:nth-child(2):hover {
  background-color: #e2e6ea; 
}


/* Para pantallas grandes (laptops y pantallas medianas) - MI LAPTOP*/
@media only screen and (min-width: 1025px) and (max-width: 1599px) {
 /*  .secondModalStyle, .informationEmployee, .leaderShipEmployee,.othersInfoEmployee {
    height: 96vh;
    max-height: 96vh;
  } */
}
/* Para pantallas muy grandes (resolución 1920x1080) computador PC */
@media only screen and (min-width: 1600px) and (max-width: 1920px) {
 /*  .secondModalStyle, .informationEmployee, .leaderShipEmployee,.othersInfoEmployee  {
    height: 96vh;
    max-height: 96vh;
  } */
}

/*New query*/
@media only screen and (min-width: 1600px) and (max-width: 2560px){
  /* .secondModalStyle, .informationEmployee, .leaderShipEmployee,.othersInfoEmployee  {
    height: 96vh;
    max-height: 96vh;
  } */
}

@media only screen and (min-width: 900px) and (max-width: 1025px) {
/*   .secondModalStyle, .informationEmployee, .leaderShipEmployee,.othersInfoEmployee {
    height: 96vh;
    max-height: 96vh;
  } */
}
