.leaderShip-table-mobile{
    font-size: 1.125em !important;
    width: auto;

}
.impact-title-mobile,.collab-title-mobile, .transform-title-mobile, .Growth-title-mobile{
    font-size: 0.9em !important;
}
.lagging-mobile, .onTrack-mobile, .exceed-mobile{
    font-size: 0.85rem;
   
}
tfoot td div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
   
  }

.leaderShip-table-mobile {
    width: auto; /* La tabla ocupa el ancho completo */
    table-layout: fixed; /* Divide las celdas proporcionalmente */
    border-collapse: collapse; /* Opcional: eliminar espacios entre celdas */
}

/* .leaderShip-table-mobile .title-cell-mobile {
    width: 60%;
}
 */
@media (max-width: 360px) and (max-height: 740px){
    .leaderShip-table-mobile .title-cell-mobile {
        width: 53% !important;
    }

    tfoot td div {
        margin-right: 0.5rem;
      }
  }

@media  (max-width: 414px) and (max-height: 896px){
   
    tfoot td div {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 5px;
      }
}

  
  
  /* Media query combinada para iPhone 12 Pro, iPhone 11 Pro, y iPhone XR */
  @media (max-width: 375px) and (max-height: 667px),
         (max-width: 390px) and (max-height: 844px),
         (max-width: 412px) and (max-height: 915px) {
            .leaderShip-table-mobile .title-cell-mobile {
                width: auto !important;
            }
            tfoot td div {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 5px;
                
               
              }
          
    
  }
  

  @media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait),
  (min-width: 768px) and (max-height: 1024px) and (orientation: landscape) {
    .leaderShip-table-mobile {
        width: 100%;
        table-layout: auto; /* Permite ajuste dinámico de las celdas */
      }
    
      .leaderShip-table-mobile .title-cell-mobile,
      .leaderShip-table-mobile .tick-cell-mobileInfo {
        width: auto; /* Distribución dinámica */
      }
    
      .leaderShip-table-mobile .tick-cell-mobileInfo {
        display: flex;
        justify-content: center; /* Centrar ticks */
        align-items: center;
      }
    
      tfoot .tick-cell-mobileInfo div {
        display: flex;
        justify-content: center; /* Centrar contenido */
        align-items: center;
        gap: 5px; /* Espaciado entre elementos */
      }
    
      tfoot .title-cell-mobile {
        width: auto; /* Igual que las demás celdas */
      }
}
