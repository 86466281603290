.rolesGroups {
  padding: 5em 0;
}

.rolesGroups__breadcrumb--container {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 2.5em;
}

.rolesGroups__breadcrumb--title {
  color: #6e7894;
  font-size: 0.875em;
  line-height: 1.25em;
  font-weight: 700;
  text-decoration: none;
}

.rolesGroups__breadcrumb--text,
.rolesGroups__breadcrumb--text a {
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  color: #6e7894;
  font-size: 0.875em;
}

.rolesGroups__info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5em;
}

.rolesGroups__title {
  /* font-size: 1.688em; */
  font-size: 1.375em;
  color: var(--color-blue);
}

.rolesPage__intro--search {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.rolesPage__searcherSection {
  display: flex;
  background-color: #f8f8f8;
  padding: 0.625em 1.25em;
  gap: 0.625em;
  border-radius: 52px;
  cursor: pointer;
  width: 20em;
}

.rolesPage__searcher {
  width: 1.25em;
  height: 1.25em;
}

.rolesPage__searcherInput {
  border: none;
  background-color: #f8f8f8;
  outline: none;
}

.rolesPage__searcherInput::placeholder {
  color: #676d83;
  font-size: 0.8753em;
}

.rolesGroups__container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5em;
}

.rolesGroups__container a {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5em;
  text-decoration: none;
}

.rolesGroups__rolesElement {
  display: flex;
  flex-direction: column;
  padding: 1.5em;
  width: 18em;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #d1d5e4;
  cursor: pointer;
  flex: 1;
}


.rolesGroups__rolesElement--title {
  color: #031a34;
  font-size: 1.25em;
  line-height: 140%;
  text-decoration-line: none;
}

.rolesGroups__rolesElement--title:hover {
  text-decoration: underline;
}

.rolesGroup__rolesElement--text {
  color: #6e7894;
  font-size: 1em;
  line-height: 150%;
  text-decoration-line: none;
}