/* CSS */
.top_talent-circle-performance {
  position: relative; 
  margin: 0; 
  padding: 0;
  left: -4px; 
}

.performance-data {
  position: absolute;
  color: #007bff;
  left: 50%; 
  top: 50%;  
  transform: translate(-85%, -60%); 
  margin-top: 0; 
}


.keytalent-tittle , .toptalent-tittle, .performance-tittle{
  font-size: 1rem !important;
}

.date-talent{
  font-size: 1rem !important;
  margin-bottom: 0rem !important;
}


@media only screen and (min-width: 1025px) and (max-width: 1599px) {
    /* Reducir el tamaño de la tabla */
    .table.table-ms {
      margin-top: 0rem !important;
      width: 100%; 
    }
  
    /* Ajustes específicos para las celdas y encabezados */
    .table.table-ms td,
    .table.table-ms th {
      padding: 0.2rem; 
    }
  
    /* Ajuste adicional para textos */
    .table.table-ms .top-talent {
      font-size: 0.7rem; 
    }
    
    /* Ajuste para el componente de rendimiento */
    .table.table-ms td span {
      position: relative;
      font-size: 0.8rem; 
    }
    
    .top_talent-circle-performance{
      position: relative;
      top: 1px;
      width: 1.7rem;
      height:  1.7rem;
    }

    .top_talent-circle-yes, .top_talent-circle-no{
      width: 1.2rem;
      height: 1.2rem;
    }

    .top_talent-circle-performance {
      position: relative; 
      margin: 0 !important; 
      padding: 0 !important;
      left: -4px; 

    }
    
    .performance-data {
      position: absolute;
      left: -60%; 
      top: 50%;  
      margin-top: 0; 
    }

    .keytalent-tittle , .toptalent-tittle, .performance-tittle, .date-talent{
      font-size: 0.7rem !important;
    }
}
  
@media only screen and (min-width: 900px) and (max-width: 1025px) {
    /* Reducir el tamaño de la tabla */
    .table.table-ms {
        margin-top: 0rem !important;
        width: 100%; 
      }

      .keytalent-tittle , .toptalent-tittle, .performance-tittle{
        font-size: 0.55rem !important;
        margin-top: 0.4rem;
      }

      .date-talent{
        font-size: 0.55rem !important;
        margin-bottom: 0rem !important;
      }
    
      /* Ajustes específicos para las celdas y encabezados */
      .table.table-ms td,
      .table.table-ms th {
        padding: 0.4rem; 
      }
    
      /* Ajuste adicional para textos */
      .table.table-ms .top-talent {
        font-size: 0.4rem; 
      }
      
      /* Ajuste para el componente de rendimiento */
      .table.table-ms td span {
        position: relative !important;
        font-size: 0.65rem;
      }
      
      /* Ajuste para el ícono de PerformanceComponent */
      .table.table-ms td span span {
        right: 10px; 
        top: -3px;
      }
      
      .top_talent-circle-yes, .top_talent-circle-no{
        width: 1rem;
        height: 1rem;
      }

      .top_talent-circle-performance {
        position: relative; 
        margin: 0 !; 
        padding: 0;
        left: -5px; 
        width: 1.5rem;
        height:  1.5rem;


      }

      .performance-data {
        position: absolute;
        left: -65% !important; 
        top: 50%;  
        margin-top: 0; 
      }

  
}