.content-others-components-scroll{
    max-height: 1000px;
    overflow-y: auto;

    
    -ms-overflow-style: none;  
    scrollbar-width: none;  

    &::-webkit-scrollbar {
        display: none;
    }
}

.leaderShip-title-mobile, .skillset-title-mobile,
.potential-title-mobile,.mobility-title-mobile,.carrer-title-mobile
,.aditional-title-mobile{
    font-size:  1.5rem !important;
    font-family: Telefonica_Sans_Light;
}
.employee-job-title{
    font-family: Telefonica Sans;

}
.aditional-content-mobile{
    height: 7rem;
}
.aditional-Text-mobile,.carrer-text-mobile,.aditional-mobilty-info-mobile{
    font-family: Telefonica Sans;
    padding-right: 1rem !important;
    text-align: justify;
}
.name-country-mobile,.potential-value-mobile,.skill-table-mobile{
    font-family: Telefonica Sans !important;
}

.skill-table-mobile, .potential-text-mobile,
.potential-year-text-mobile,.succesion-text-mobile
,.successors-text-mobile,.Mobility-content,
.aditional-mobilty-info-mobile,.carrer-text-mobile{
    font-size: 1.15rem !important;
}
