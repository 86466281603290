.featuredContent__article {
  padding: 5em 0;
}

.featuredContent__article--title {
  color: var(--color-blue);
  /* font-size: 1.688em; */
  margin-bottom: 1em;
  font-size: 1.375em;
}

.featuredContent__article--search {
  color: var(--color-black);
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 2.5em;
}

.featuredContent__article__breadcrumb--container {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 2.5em;
}

.featuredContent__article__breadcrumb--title {
  color: #6e7894;
  font-size: 0.75em;
  line-height: 1.25em;
  font-weight: 700;
  text-decoration: none;
}

.featuredContent__article__breadcrumb--text {
  color: #6e7894;
  font-size: 0.75em;
  line-height: 1.25em;
  font-weight: 400;
  text-decoration: none;
}
