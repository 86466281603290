.popup-modal-overlay,
.deletePopup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0000007d !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.popup-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  width: 100%;
}

.dataView__firstSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.infoIcon {
  height: 14px;
  width: 14px;
  margin-left: 1em;
  cursor: pointer;
}
