.custom-bg {
    background-color: #F0F0F0;
  }

.ob-title.cluster-title{
  font-family: Telefonica Sans !important; 
}

.dropdown{
  width: 280px !important;
    height: 200px !important;
}
.add-icon{
   width: 55px;
   height: 55px;
}

.delete-icon{
  width: 55px;
  height: 55px;
}

/* Estilo general para el contenedor de selección */
.basic-multi-select {
  max-height: 200px;
  overflow-y: auto; 
  white-space: nowrap; 
  position: relative;
}

/* Oculta visualmente la barra de desplazamiento en navegadores basados en WebKit */
.basic-multi-select::-webkit-scrollbar {
  width: 0;
  height: 0; 
  background: transparent;
}

/* Configuración para navegadores que usan el estándar de Mozilla */
.basic-multi-select {
  scrollbar-width: none; 
}

/* Contenedor de los valores seleccionados con scroll interno */
.basic-multi-select .select__value-container {
  max-height: 50px; 
  overflow-y: auto; /* Habilita el scroll solo en los valores seleccionados */
  white-space: nowrap; /* Mantiene todo en una sola línea */
}

/* Estilo para los valores seleccionados */
.basic-multi-select .select__multi-value {
  display: inline-flex;
  max-width: 100%;
}

.basic-multi-select .select__multi-value__label {
  overflow: hidden;
  text-overflow: ellipsis; 
  white-space: nowrap; 
}


/* Media query para pantallas de portátiles de 17 pulgadas (1920px de ancho) */
@media only screen and (min-width: 1025px) and (max-width: 1599px) {
  .dropdown{
    width: 180px !important;
    height: 180px !important;
  }
  .add-icon{
    width: 45px !important;
    height: 45px !important;
  }
  .delete-icon{
    width: 45px;
    height: 45px;
  }
}
/* Media query para pantallas de portátiles de 17 pulgadas (1920px de ancho) */
@media only screen and (min-width: 900px) and (max-width: 1025px) {
  .dropdown{
    width: 180px !important;
    height: 180px !important;
    margin-left: 2.5rem;
  }
 
  .add-icon{
    width: 45px !important;
    height: 45px !important;
  }

  .delete-icon{
    width: 45px;
    height: 45px;
  }


}