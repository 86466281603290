
.title-modal-payroll {
    font-size: 24px;
    font-weight: bold;
    margin: 25px;
    color:  var(--color-blue);
  }
  
  .description-modal-payroll {
    font-size: 16px;
    margin-bottom: 25px;
    color: #6e7894;
    text-align: center;
    max-width: 600px;     
    margin-left: auto;    
    margin-right: auto;   
    line-height: 1.5;  
  }
  
  .button-modal-payroll {
    background-color: var(--color-blue);
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .button-modal-payroll:hover {
    background-color: #6098cc;
  }