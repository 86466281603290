.categories {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2em;
}

.categories__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.categories__breadcrumb {
  display: flex;
  align-items: center;
  gap: 0.5em;
  /* margin-bottom: 1em; */
}

.categories__breadcrumb--title {
  color: #6e7894;
  font-size: 0.875em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25em;
  margin-bottom: 0 !important;
}

.cirle-icon {
  width: 0.625rem;
  height: 0.625rem;
  color: var(--color-blue);
}

.bubble__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-bottom: 1.875em;
  width: 12em;
  text-decoration: none;
}

.bubble {
  /* width: 7.5em;
  height: 7.5em; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

/* .bubble__infoContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
} */

.bubble img {
  width: 5em;
  height: 5em;
  border-radius: 50%;
}

.bubble__title {
  color: var(--color-black);
  font-size: 1em;
  line-height: 1.5rem;
  width: 13em;
  text-align: center;
}

.bubble__title:hover {
  color: var(--color-blue);
}

.manager_tag {
  display: flex;
  padding: 0.25em 1em;
  align-items: flex-start;
  gap: 0.625em;
  border-radius: 2.9375em;
  background: var(--color-blue);
  color: var(--color-white);
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5em;
  margin-top: 3.5em;
}

/* BUBBLE */
.circle1_categories {
  width: 0.75em;
  height: 0.75em;
  background-color: var(--color-blue);
  margin: 0.313em 0.313em 2.5em;
  border-radius: 50%;
}

.circle2_categories {
  width: 0.75em;
  height: 0.75em;
  background-color: var(--color-turquoise);
  margin: 0.313em 0.313em 2.5em;
  border-radius: 50%;
}

/* TAG DEL MANAGER */
.managerTag {
  padding: 0.5em 1em;
  border-radius: 47px;
  background: var(--color-blue);
  margin-bottom: 1em;
  width: max-content;
  text-align: center;
}

.managerTag__text {
  font-size: 1em;
  color: var(--color-white);
  margin-bottom: 0 !important;
}

.bubble__description {
  color: #6e7894;
  font-size: 1em;
  margin-right: 1em;
  line-height: 150%;
}

@media (min-width: 2000px) {
  .categories__container {
    gap: 1.25em 7em;
  }
}

@media (max-width: 2000px) {
  .categories__container {
    gap: 1.25em 7em;
  }
}

@media (max-width: 1800px) {
  .categories__container {
    gap: 1.25em 2em;
  }
}

@media (max-width: 1400px) {
  .categories__container {
    gap: 10px 30px;
  }
}

@media (max-width: 1250px) {
  .bubble__container {
    width: 10em;
  }
}

@media (max-width: 900px) {
  .categories__container {
    gap: 20px 0px;
  }
}

@media (max-width: 500px) {
  .categories__container {
    justify-content: space-around;
    gap: 20px;
  }

  .bubble__container {
    align-items: center;
    gap: 10px;
  }

  .bubble__title {
    text-align: center;
    font-weight: 600;
    height: auto;
  }

  .bubble {
    height: 6em;
    width: 6em;
  }

  .bubble__infoContainer {
    align-items: center;
    gap: 10px;
  }

  .managerTag {
    margin-bottom: 0;
  }

  .bubble__description {
    text-align: start;
  }
}
