.footer {
  margin-top: 5em;
  padding-bottom: 2.5em;
}
.footer__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5em;
}
.tfo__logo {
  height: 32px;
  width: 120px;
}
.footer__links {
  color: var(--color-blue);
  display: flex;
  gap: 2.5em;
  list-style: none;
}
.footer__links--url {
  color: var(--color-blue);
  text-decoration: none;
  font-size: 0.875em;
  text-decoration: none;
}

/* Contact us button */
.footer__contactUsButtonSection {
  display: none;
}

@media (max-width: 500px) {
  .footer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top: 1px solid #d0d0d0;
    padding-bottom: 0;
    gap: 1.5em;
  }
  .footer__container {
    display: flex;
    flex-direction: column-reverse;
    gap: 1.5em;
  }
  .footer__links {
    align-items: flex-start;
    width: 80%;
    flex-wrap: wrap;
    gap: 1.5em 2.5em;
    font-size: 0.875em;
  }

  /* contactUs button */
  .footer__contactUsButtonSection {
    display: block;
    margin-top: 1.5em;
  }
  .footer__contactUsButton {
    background-color: var(--color-blue);
    padding: 10px 24px;
    border-radius: 100px;
    font-size: 1em;
    color: var(--color-white);
    border: none;
    cursor: pointer;
  }

  .footer__contactUsButton:hover {
    opacity: 0.7;
    transition: all 0.35s ease-in;
  }
}
