.summary-container{
    width: auto;
    height: auto;

}
.employeeName-sumComp{
    font-family: Telefonica Sans !important;
}

.summary-Tittle-sumComp{
    font-size:  2rem ;
    font-family: Telefonica_Sans_Light;
}
.logo-summary-sumComp {
    width: 30px !important;
    height: 30px !important;
}

.employee-details-sumComp{
    margin-top: 2rem !important;
}
.summary-Text-sumComp{
   /*  font-size: 1.12rem; */
    font-family: 'Telefonica Sans', sans-serif !important;  
    max-height: 700px;
    overflow-y: auto;
    text-align: justify;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    /* Oculta la barra de desplazamiento para Chrome, Safari y Opera */
    &::-webkit-scrollbar {
        display: none;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1025px) {
    .summary-container {
        height: 100vh;
        max-height: 100vh;
    }
    .summary-container .logo-summary-sumComp{
        width:15px !important;
        height: 15px !important;
    }
    .summary-container .employee-details-sumComp{
        margin-top: 1.6rem !important;
    }
    /*Title*/
    .summary-container .employeeName-sumComp {
        font-size: 1rem !important;
    }
    
    /*Subtitle*/
    .summary-container  .job-Subtitle-sumComp{
        font-size: 0.95rem !important;
    }
    .summary-container .summary-Tittle-sumComp{
        font-size:  1.1rem !important;
    }
    .summary-container .summary-Text-sumComp{
        font-size: 0.7rem;
    } 
}

/* Para pantallas grandes (laptops y pantallas medianas) - MI LAPTOP*/
@media only screen and (min-width: 1025px) and (max-width: 1599px) {
    .summary-container {
        height: 100vh;
        max-height: 100vh;
    }

    .logo-summary-sumComp{
        width:20px !important;
        height: 20px !important;
    }

    .summary-container .employee-details-sumComp{
        margin-top: 1.8rem !important;
    }

    /*Title*/
    .summary-container .employeeName-sumComp{
        font-size: 1.2rem !important;
    }
    
    /*Subtitle*/
    .summary-container .job-Subtitle-sumComp{
        font-size: 1rem !important;
    }

    .summary-container .summary-Tittle-sumComp{
        font-size:  1.2rem !important;
    }

    .summary-Text-sumComp{
        font-size: 0.85rem !important;
    }
   
}
  

 /*New Query*/
@media only screen and (min-width: 1600px) and (max-width: 2560px) {
    .summary-container{
        margin-top: 1.5rem !important;
        height: 100vh;
        max-height: 100vh;
    }
    .logo-summary-sumComp{
        width: 25px !important;
        height: 25px !important;
        font-family: Telefonica Sans;

    }

    /*Subtitle*/
    .employeeName-sumComp{
        font-size: 2rem !important;
        font-family: Telefonica Sans;

    }
    .job-Subtitle-sumComp{
        font-size: 1.8rem !important;
        margin-bottom: 1rem !important;
        font-family: Telefonica Sans;


    }
    .summary-container .summary-Tittle-sumComp{
        font-size:  1.5rem !important;
        font-family: Telefonica_Sans_Light;
    }

    .summary-Text-sumComp{
        font-size: 1.2rem;
        font-family: Telefonica Sans;
        text-align: justify;
    }
}