.header__login {
  height: 10vh;
  background-color: #0061fe;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.563em 1.563em;
}

.tf__logo {
  height: 1.875em;
  opacity: 0.9;
  cursor: pointer;
}

.login {
  padding: 0 2.7em;
  height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  background-image: url(../images/LogIn.png);
  background-size: cover;
  background-position: center;
}
.login__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* border: 1px solid red; */
  gap: 2em;
}

.peoplEx-icon {
  width: 8em;
}

.login__desc {
  max-width: 17em;
  font-size: 1.438em;
  line-height: 2.5rem;
  color: #676d83;
}

.login__title {
  font-weight: light;
  margin-bottom: 50px;
  font-size: 2.813em;
  color: var(--color-white);
}

.login__button {
  background-color: var(--color-blue);
  text-decoration: none;
  color: var(--color-white);
  font-size: 1em;
  padding: 0.75em;
  text-align: center;
  border-radius: 50px;
}

.login__button:hover {
  cursor: pointer;
  background-color: #0040b3;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Pop up */
@keyframes popup {
  0% {
    top: -100%;
  }

  100% {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/* Sombra fondo */
.login-shade {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.login-shade.active {
  display: block;
}

/* Pop up legal  */
.legal-popup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1em;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  width: 50em;
  border-radius: 0.25rem;
  z-index: 2;
  animation: popup 0.8s ease forwards;
}
.loginComponent {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1em;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  width: 50em;
  border-radius: 0.25rem;
  z-index: 2;
  animation: popup 0.8s ease forwards;
}
.loginComponent.active {
  display: block;
}

.legal-popup.active {
  display: block;
  overflow-y: scroll;
  height: 80%;
}

.legal__title {
  font-size: 1.5em;
  line-height: 1.5;
}

.legal__desc {
  color: #333;
  margin: 1.563em 0;
  font-size: 0.875em;
  line-height: 1.5;
  text-align: justify;
}
.legal__desc ul {
  padding: 1em;
}

.legal__desc--bold {
  font-weight: bold;
}

.legal__desc a {
  color: var(--color-blue);
  text-decoration: none;
}

.legalbutton__section {
  border-top: 1px solid #dee2e6;
  display: flex;
  justify-content: flex-end;
}

.legalbutton {
  margin: 1em 0 0 0;
  float: right;
  color: var(--color-white);
  background-color: var(--color-blue);
  border: 1px solid var(--color-blue);
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.legalbutton:hover {
  background-color: #138496;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (max-width: 1000px) {
  .peoplEx-icon {
    width: 14em;
    margin-bottom: 2em;
    margin-top: 3em;
  }

  .login__button {
    width: 10em;
    height: 2.8em;
    line-height: 2.8em;
  }

  .header__login {
    background-color: #0061fe;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5em 1.563em;
  }

  .login__title {
    margin-bottom: 40px;
    font-size: 2.5em;
  }
}

@media (max-width: 700px) {
  .header__login {
    height: 5em;
  }

  .tf__logo {
    height: 1.8em;
  }

  .peoplEx-icon {
    width: 8em;
    margin-bottom: 2em;
    margin-top: 3em;
  }

  .login__desc {
    max-width: 250px;
    margin-bottom: 1.563em;
    font-size: 1.25em;
    line-height: 1.5;
    color: var(--color-white);
  }

  .login__title {
    margin-bottom: 40px;
    font-size: 35px;
    max-width: 250px;
  }
}

/* @media (max-width: 1000px) {
  .login {
    height: 90vh;
    background-image: url(../images/Fondo_movil.png);
    background-size: cover;
  }
} */
