.tittleHeader{
    font-family: Telefonica Sans; 
    color:#06f;
}
.talent-title{
    font-family: Telefonica Sans;
}
.hub-title{
    font-family: Telefonica_Sans_Light;;

}