.other-container{
    width: auto;
    height: auto;
}


/*Estilo titles*/
.potential-title, .mobility-title,.carrer-title, .aditional-title{
    font-family: Telefonica_Sans_Light;
    font-size: 2rem;
}

.country{
    width: 30px;
    height: 30px;
}
.name-country{
    font-size:1.05em;
}

/* confirm-alert-custom.css */
.react-confirm-alert-overlay {
    background-color: rgba(0, 0, 0, 0.5); 
  }
  
  .react-confirm-alert-body {
    background-color: #ffffff; 
    border-radius: 8px; 
    padding: 20px; 
    max-width: 450px; 
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); 
    color: #333333;
    font-family: Telefonica_Sans_Light;
    text-align: center; 
  }
  
  .react-confirm-alert-button-group {
    display: flex;
    justify-content: center;
    gap: 20px; 
    margin-top: 20px; 
  }
  
  .react-confirm-alert-button-group button {
    padding: 10px 30px; 
    border: none; 
    border-radius: 20px; 
    cursor: pointer; 
    font-weight: bold; 
    color: #ffffff;
    font-size: 16px; 
    background-color: #007BFF;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
    transition: background-color 0.3s; 
  }
  
  .react-confirm-alert-button-group button:hover {
    background-color: #0056b3; 
  }
  
  
  .react-confirm-alert-button-group button:nth-child(2) {
    background-color: #f8f9fa;
    color: #333333; 
  }
  
  .react-confirm-alert-button-group button:nth-child(2):hover {
    background-color: #e2e6ea; 
  }

.cluster-exp-others{
    cursor: pointer;
}

@media only screen and (min-width: 900px) and (max-width: 1025px){
    .other-container{
        margin-top: 10PX;
        height: 100vh;
        max-height: 100vh;
    }
    .svg-Style{
        width: 160px !important;
        height: 50px !important;
        margin-top: 25px;
        margin-left: 0px;
    }
    .rect-style{
      fill: #007bff;
      rx: 23;
      ry: 23;
      width: 120px;
      height: 50px;
    }
    .ceo-SVG{
        fill: white;
        font-size: 1rem;
        text-anchor: middle;
    }
    /*CLUSTER STYLE*/
    .svg-Style-cluster{
        width: 160px;
        height: 50px;
        margin-top: 25px;
        margin-left: 20px;
    }
    .cluster-SVG{
        fill: white;
        font-size: 1rem;
        text-anchor: middle;
    }
    .rect-style-cluster{
        fill: #007bff;
        rx: 23;
        ry: 23;
    }
    
     /*Title*/
    .potential-title{
        margin-top: 1rem ;
        font-size: 1rem !important;

    }
    .mobility-title{
        font-size: 1rem !important;

    }
    .carrer-title, .aditional-title{
        font-size: 1rem !important;
        margin-top: 1rem;
    }

    .potencial-value{
        font-size: 0.75rem !important;
        margin-bottom: 1rem;
    }
    .carrer-action, .aditional-Text, .aditional-mobilty-info{
        font-size: 0.75rem !important;
        padding-right: 0.3rem;
    }
    .country{
        width: 20px;
        height: 20px;
    }
    .name-country{
        font-size: 0.75rem !important;
    }


}


@media only screen and (min-width: 1025px) and (max-width: 1599px){
    .other-container{
       height: 100vh;
       max-height: 100vh;
    }
    .svg-Style{
        width: 160px;
        height: 50px;
        margin-top: 25px;
        margin-left: 0px;
    }
    .rect-style{
      fill: #007bff;
      rx: 23;
      ry: 23;
      width: 150px;
      height: 50px;
    }
    .ceo-SVG{
        fill: white;
        font-size: 1rem;
        text-anchor: middle;
    }
    /*CLUSTER STYLE*/
    .svg-Style-cluster{
        width: 160px;
        height: 50px;
        margin-top: 25px;
        margin-left: 20px;
    }
    .cluster-SVG{
        fill: white;
        font-size: 1rem;
        text-anchor: middle;
    }
    .rect-style-cluster{
        fill: #007bff;
        rx: 23;
        ry: 23;
    }
    
     /*Title*/
    .potential-title{
        margin-top: 0.3rem ;
        margin-bottom: 0.5rem ;
        font-size: 1.2rem !important;
    }
    .mobility-title{
        margin-top: 1rem ;
        font-size: 1.2rem !important;
    }
    .carrer-title, .aditional-title{
        font-size: 1.2rem !important;
        margin-top: 1rem;
    }
    .potencial-value{
        font-size: 0.85rem !important;
        margin-top: 0rem;
    }
    .carrer-action, .aditional-Text, .aditional-mobilty-info{
        font-size: 0.85rem !important;
        padding-right: 0.8rem;
    }
    .country{
        width: 20px;
        height: 20px;
    }
    .other-container .name-country{
        font-size: 0.8rem !important;
    }

}

/* Para pantallas muy grandes (resolución 1920x1080) computador PC */
@media only screen and (min-width: 1600px) and (max-width: 2560px) {
    .other-container{
        height: 100vh;
        max-height: 100vh;
        margin-top: 20px;
    }
    .svg-Style{
        width: 160px;
        height: 50px;
        margin-top: 25px;
        margin-left: 0px;
    }
    .rect-style{
      fill: #007bff;
      rx: 23;
      ry: 23;
    }
    .ceo-SVG{
        fill: white;
        font-size: 1rem;
        text-anchor: middle;
    }
    /*CLUSTER STYLE*/
    .svg-Style-cluster{
        width: 160px;
        height: 50px;
        margin-top: 25px;
        margin-left: 20px;
    }
    .cluster-SVG{
        fill: white;
        font-size: 1rem;
        text-anchor: middle;
    }
    .rect-style-cluster{
        fill: #007bff;
        rx: 23;
        ry: 23;
    }
    /*Title*/
    .potential-title{
        margin-bottom: 0.5rem ;
        margin-top: 0.5rem ;
        font-size: 1.5rem !important;

    }
    .mobility-title{
        font-size: 1.5rem !important;
        

    }
    .carrer-title, .aditional-title{
        font-size: 1.5rem !important;
        margin-top: 1rem;
    }
    .potencial-value{
        font-size: 1.15rem  !important;
        margin-bottom: 0.9rem ;

    }
    .carrer-action, .aditional-Text{
        font-size: 1.15rem ;
        padding-right: 0.8rem;
    }
    .aditional-mobilty-info{
        font-size: 1.15rem ;

    }
    .country{
        width: 30px;
        height: 30px;
    }
    

}