.myFolderSection_certificateOptions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1em;  
}

.checkbox-list {
  display: flex;
  flex-direction: column;
  margin-top: -5em;
  width: 20%;
  margin-left: 0em;
}

.checkbox-list div {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #D1D5E4;
  padding: 0.5em 0;
}

.MyFolderSection_message {
  margin-top: 1rem;
  width: 20%;
  color: #6E7894;
  font-size: 0.9rem;
  text-align: justify;
}
.email {
  color: var(--color-blue);
  text-decoration: none;

}

.checkbox-list div:last-child {
  border-bottom: none; 
}

.checkbox-list input[type="checkbox"] {
  margin-right: 1em; 
}

.MyFolderSection_getCertificate {
  width: 110px;
  margin-top: 1em; 
  border-radius: 2.5em; 
  border: 1.5px solid var(--color-blue); 
  background-color: var(--color-blue); 
  color: var(--color-white); 
  padding: 0.5em; 
  cursor: pointer;
}
.MyFolderSection_getCertificateDownload {
  width: 110px;
  margin-top: 1em; 
  border-radius: 2.5em; 
  border: 2px solid var(--color-blue) ; 
  background-color: var(--color-blue); 
  color: var(--color-white); 
  padding: 0.5em; 
  cursor: pointer;
}

.MyFolderSection_getCertificate:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.MyFolderSection_getCertificatePDF {
  display: flex;
  position: absolute;
  margin-left: 12em;
  margin-top: -5em;
  width: 40%;
  height: 66%;
}

.MyFolderSection_certificatePDF {
  width: 100%;
  height: 100%;
  border: none; 
  padding: 0; 
}

.MyFolderSection_certificatePDF img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border: none; 
}


/* Ajustes para pantallas extra grandes */
@media (max-width: 2000px) {
  .MyFolderSection_getCertificatePDF {
    width: 30%;
    margin-left: 20em;
  }
}

/* Ajustes para pantallas grandes pero no de escritorio completo */
@media (min-width: 1200px) and (max-width: 1399px) {
  .MyFolderSection_getCertificatePDF {
    width: 40%;
    margin-left: 15em;
     
  }
}

/* Ajustes para pantallas medianas */
@media (min-width: 900px) and (max-width: 1199px) {
  .myFolderSection_certificateOptions {
    align-items: left;
    margin-top: 0;
  }
  .checkbox-list {
    width: 25%;
  }

  .MyFolderSection_getCertificatePDF {
    
    margin-left: 14em;
    width: 40%;
    height: 40%;
  }

  .MyFolderSection_certificatePDF {
    width: 100%;
    height: 100%;
  }
}

/* Ajustes para tablets */
@media (min-width: 700px) and (max-width: 899px) {
  .myFolderSection_certificateOptions {
    align-items: left;
    
  }
  .checkbox-list {
    width: 40%;
  }

  .MyFolderSection_getCertificatePDF {
    position: static;
    margin: 2em 0;
    width: 80%;
    
  }
  .MyFolderSection_getCertificateDownload {
    margin-left: 9em;
    margin-top: -2.4em;
  }

  .MyFolderSection_certificatePDF {
    width: 50%;
    height: 70%;
  }
  .MyFolderSection_message {
    font-size: 0.6rem;
    width: 40%;
  }
}

/* Ajustes para móviles grandes */
@media (min-width: 500px) and (max-width: 699px) {
  .myFolderSection_certificateOptions {
    align-items: left;
    margin-top: 0;
  }
  .checkbox-list {
    width: 50%;
  }

  .MyFolderSection_getCertificatePDF {
    position: static;
    margin: 1em 0;
    width: 70%;
    height: auto;
  }
  .MyFolderSection_getCertificateDownload {
    margin-left: 9em;
    margin-top: -2.4em;
  }

  .MyFolderSection_certificatePDF {
    width: 100%;
    height: auto;
  }
  .MyFolderSection_message {
    font-size: 0.5rem;
    width: 70%;
  }
}

/* Ajustes para móviles pequeños */
@media (max-width: 499px) {
  .myFolderSection_certificateOptions {
    align-items: left;
    margin-top: 0;
  }

  .checkbox-list {
    width: 100%;
    font-size: 0.7em;
  }

  .MyFolderSection_getCertificatePDF {
    position: static;
    margin: 1em 0;
    width: 100%;
    height: auto;
  }

  .MyFolderSection_certificatePDF {
    width: 100%;
    height: auto;
  }
  .MyFolderSection_message {
    font-size: 0.7rem;
    width: 100%;
  }
}
