.aboutUsPage {
  padding: 5em 0;
}

.aboutUsPage__breadcrumb {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 2.5em;
  font-size: 0.75em;
}

.aboutUsPage__breadcrumb--title {
  color: #6e7894;
  line-height: 1.25em;
  font-weight: 400;
  text-decoration: none;
  font-size: 1.2em;
}

.aboutUsPage__breadcrumb--text {
  font-weight: 400;
}

.aboutUsPage__content {
  display: flex;
  flex-direction: column;
  gap: 2.5em;
}

.aboutUsPage__title {
  color: var(--color-blue);
  /* font-size: 1.688em; */
  font-size: 1.5em;
}

.aboutUsPage__text {
  color: var(--color-black);
  font-size: 1em;
  line-height: 25.6px;
  text-align: justify;
}

.aboutUsPage__employees {
  width: 100%;
}