
.custom-modal .modal-content {
    max-width: 400px; 
    margin: auto;
    font-family: 'Telefonica Sans', sans-serif; 
    border-radius: 24px; 
}

.btn-acept-leaderShip {
    background-color: white;
    color: #0066FF; 
    border: 1px solid #0066FF;
}

.btn-acept-leaderShip:hover {
    background-color: #0066FF;
    color: white; 
    cursor: pointer;
    opacity: 0.9;
  
}


.btn-delete-leaderShip {
    background-color: #E66C64 !important; 
    color: white; 
    border: none;
 
}

.btn-delete-leaderShip:hover {
    background-color: #cf2519 !important; 
    opacity: 0.85;
    cursor: pointer;
    
   
}

.blue-title {
    color: var(--color-blue); 
    margin-bottom: 2em; 
    font-size: 22px !important;
}
.col-form-label {
    font-size: 20px !important; 
    text-align: left !important;
    width: 60%;
}
.gray-select { 
    padding: 2px;
    border: 1px solid #ccc;
    background-color: #F0F0F0;
    background-image: url('data:image/svg+xml;utf8,<svg fill="gray" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
    background-repeat: no-repeat;
    background-position: right 8px center;
    cursor: pointer;
    margin-bottom: 1em;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    align-items: center;
  }