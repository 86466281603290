.leadership-container {
    border-right: 1.2px solid #0066FF; 
    padding-right: 1rem;
    padding-bottom: 8rem;
  }
.employee-title{
  font-size: auto;
  font-family: Telefonica Sans;

}
.job-title{
  font-size: auto;
  font-family: Telefonica Sans;

}
.logo-summary{
  width: auto !important;
  height: auto !important;
}


.skill-table{
  font-size: auto;
}

@media only screen and (min-width: 900px) and (max-width: 1025px) {
  .leadershipcomponent-container{
    height: 100vh;
    max-height: 100vh;
  }
  .logo-summary-leadership
  {
    width:15px !important;
        height: 15px !important;

  }
  .employee-title {
    font-size: 1rem !important;
  }
  .job-title {
    font-size: 0.95rem !important;
  }
  .leaderShip-title, .skillset-title {
    font-size: 1rem !important;
    margin-bottom: 0.7rem !important;
    font-family: Telefonica_Sans_Light;

  }
  .skill-table {
    font-size: 0.60rem !important;
   
  }
  .leadership-container {
    padding-bottom: 1.2rem !important;
    
  }
  .leaderShip-title{
    font-size:  1rem !important;
    font-family: Telefonica_Sans_Light;
  }
}

@media only screen and (min-width: 1025px) and (max-width: 1599px){
  .leadershipcomponent-container{
    height: 100vh;
    max-height: 100vh;
  }

  .employee-title{
    font-size: 1.2rem !important;
    font-family: Telefonica Sans !important;
  
  }

   .logo-summary-leadership
  {
    width:20px !important;
    height: 20px !important;

  }

  .job-title{
    font-size: 1rem !important;
    font-family: Telefonica Sans !important;
  }

  .leaderShip-title , .skillset-title{
    font-size:  1.2rem !important;
    font-family: Telefonica_Sans_Light;
  }

  .skill-table{
    font-size: 0.7rem !important;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 2560px){
  .leaders-container{
    height: 100vh;
    max-height: 100vh;
  }
  .logo-summary-leadership{
    width: 25px !important;
    height: 25px !important;

  }
  .employee-title {
    font-family: Telefonica Sans;
  }
  
  .job-title{
    margin-bottom: 1rem !important;
    font-family: Telefonica Sans;
    
  }
  
  .leaderShip-title{
    font-size: 1.5rem !important;
    font-family: Telefonica_Sans_Light;
  }
  
  .skillset-title{
    font-size: 1.5rem !important;
    margin-top: 3rem !important;
    font-family: Telefonica_Sans_Light;
  }
  
  .skill-table{
    font-size: 1rem;
    font-family: Telefonica Sans;

  }
}