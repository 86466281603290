.copyContainer {
  position: relative;
}
.copyButton {
  height: 14px;
  width: 14px;
  cursor: auto !important;
  border: none;
  float: right;
  margin: 1em;
  width: 100%;
  background: none;
}
.copyIcon__img {
  width: 14px;
  height: 14px;
  float: right;
  padding: 0 !important;
  cursor: pointer;
}
