.frequentQuestions {
  margin-top: 5em;
}

.frequentQuestions__breadcrumb {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 2.5em;
}

.frequentQuestions h4 {
  color: #6e7894;
  font-size: 0.875em;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25em;
}

.cirle-icon {
  width: 0.625rem;
  height: 0.625rem;
  color: var(--color-blue);
}

.frequentQuestions__content {
  gap: 1em;
  display: flex;
  flex-direction: column;
}

.article {
  padding: 18px 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.1s ease-in;
  -webkit-transition: all 0.1s ease-in;
  border-radius: 2.875em;
  border: 1px solid #d1d5e4;
}

.article__title {
  color: var(--color-blue);

  font-size: 1em;
  font-weight: 600;
}

.article__plus {
  width: 1em;
}

@media (max-width: 1024px) {
  .article {
    width: auto;
  }
}

@media (max-width: 820px) {
  .article__title {
    font-size: 0.875em;
  }

  .frequentQuestions__title {
    font-size: 1.25em;
  }
}

@media (max-width: 680px) {
  .frequentQuestions__title {
    font-size: 1.5em;
  }

  .article__title {
    font-size: 1em;
    line-height: 1.5;
  }
}
