.leaderShip-table{
    font-size: 1.125em !important;
    cursor: pointer;

}
.impact-title,.collab-title, .transform-title, .growth-title-ship{
    font-size: 1em !important;
}
.lagging, .onTrack, .exceed{
    font-size: auto;
}


.leaderShip-table {
    width: auto; 
    table-layout: fixed; 
    border-collapse: collapse; 
}



@media only screen and (min-width: 900px) and (max-width: 1025px) {
    
    .leaderShip-table {
        width: 100%;
        table-layout: auto; /* Permite ajuste dinámico de las celdas */
      }
    
      .leaderShip-table .title-cell,
      .leaderShip-table .tick-cell {
        width: auto; /* Distribución dinámica */
         font-size: 0.68rem !important; 

      }
      .lagging, .onTrack, .exceed{
        font-size: 0.55rem;
       
    }
    
      .leaderShip-table .tick-cell {
        display: flex;
        justify-content: center; /* Centrar ticks */
        align-items: center;
      }
    
      tfoot .tick-cell div {
        display: flex;
        justify-content: center; /* Centrar contenido */
        align-items: center;
        gap: 5px; /* Espaciado entre elementos */
      }
    
      tfoot .title-cell {
        width: auto; /* Igual que las demás celdas */
      }

      .yesleadership, .noleadership{
        width: 18px;
        height: 18px;
      }
}

/* Media query para pantallas más pequeñas */
@media only screen and (min-width: 1025px) and (max-width: 1599px) {

    .leaderShip-table {
        width: 100%;
        table-layout: auto; /* Permite ajuste dinámico de las celdas */
      }
    
      .leaderShip-table .title-cell,
      .leaderShip-table .tick-cell {
        width: auto; /* Distribución dinámica */
         font-size: 0.9rem !important; 

      }
      .lagging, .onTrack, .exceed{
        font-size: 0.7rem;
       
    }
    
      .leaderShip-table .tick-cell {
        display: flex;
        justify-content: center; /* Centrar ticks */
        align-items: center;
      }
    
      tfoot .tick-cell div {
        display: flex;
        justify-content: center; /* Centrar contenido */
        align-items: center;
        gap: 5px; /* Espaciado entre elementos */
      }
    
      tfoot .title-cell {
        width: auto; /* Igual que las demás celdas */
      }

      .yesleadership, .noleadership{
        width: 25px;
        height: 25px;
      }
  }
  

/*Query*/
@media only screen and (min-width: 1600px) and (max-width: 2560px){
    .leaderShip-table {
        width: 100%;
        table-layout: auto; /* Permite ajuste dinámico de las celdas */
      }
    
      .leaderShip-table .title-cell,
      .leaderShip-table .tick-cell {
        width: auto; /* Distribución dinámica */
        font-size: 1.2rem !important; 

      }
      .lagging, .onTrack, .exceed{
        font-size: 0.9rem;
       
    }
    
      .leaderShip-table .tick-cell {
        display: flex;
        justify-content: center; /* Centrar ticks */
        align-items: center;
      }
    
      tfoot .tick-cell div {
        display: flex;
        justify-content: center; /* Centrar contenido */
        align-items: center;
        gap: 5px; /* Espaciado entre elementos */
      }
    
      tfoot .title-cell {
        width: auto; /* Igual que las demás celdas */
      }

      .yesleadership, .noleadership{
        width: 30px;
        height: 30px;
      }
   
}