
    .card-employee {
    width: 40rem !important;
    height: 9rem !important;
    cursor: pointer !important;
    border: none !important; 
  }

  .Imagen-main {
    width: 90px !important;
    height: 90px !important;
    object-fit: cover !important;
    background: #007bff !important;
    border-radius: 50% !important; 
  }

  .cluster-card{
    width: auto !important;
  }
  


/* Media query para pantallas de portátiles de 17 pulgadas (1920px de ancho) */
@media only screen and (min-width: 1025px) and (max-width: 1599px) {
    .card-employee {
        width: 30rem !important;
        height: 8rem !important;
    }
    .Imagen-main {
        width: 70px !important;
        height: 70px !important;
    }
    
    .card-employee .card-title{
        font-size:1.2rem !important;

    }
    .card-employee .card-text{
        font-size: 0.98rem !important;
    }
      
}
  

@media only screen and (min-width: 900px) and (max-width: 1024px){
    .card-employee {
        width: 30rem !important;
        height: 9rem !important;
    }
    .Imagen-main {
        width: 70px !important;
        height: 70px !important;
    }
    
    .card-title{
        font-size:1.3rem !important;

    }
    .card-text{
        font-size: 1.10rem !important;
    }
}