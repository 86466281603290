.formJiraEnd_terminate {
  display: flex;
  flex-direction: column;
}

.formJiraEnd__title {
  color: var(--color-blue);
  font-size: 1.375em;
  margin-bottom: 1.5em;
}

.formArticle__text {
  color: var(--textPrimary, #031a34);
  font-family: Telefonica Sans;
  font-size: 16px;
  font-style: normal;
  line-height: 160%;
  margin-bottom: 1.5em;
}

.formJiraEnd_form {
  gap: 1em;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.formJiraEndSection {
  flex-direction: flex;
  align-items: center;
  gap: 1em;
}

.formJiraEndSection_inline {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* .formJiraEndSection-inline {
  align-items: baseline;
  display: flex;
  gap: 1em;
  margin-bottom: 20px;
  margin-top: 20px;
} */

.formJiraEndSection_type {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formJiraEndDuration {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.formJiraEndRadioDuration {
  display: flex;
  gap: 1em;
  margin-left: 10px;
}

.formJiraEndLabel_typeinfo {
  flex-grow: 1;
}

.formJiraEndLabel {
  line-height: 160%;
  color: #6c6c6c;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* margin-bottom: 20px; */
  margin-top: 20px;
}

.formJiraEndLabel_short,
.formJiraEndLabel_short2 {
  line-height: 160%;
  color: #6c6c6c;
  width: auto;
  margin-right: 10px;
  white-space: nowrap;
  flex-shrink: 0;
}

.formJiraEndLabel_short2 {
  margin-left: 10px;
}

.formJiraEndLabel_type {
  font-weight: 600;
  line-height: 160%;
  color: #6c6c6c;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.formJiraEndInputText_short,
.formJiraEndInputText_short2 {
  width: auto;
  flex-grow: 1;
}

.formJiraEndInputText,
.formJiraEndInputText_short,
.formJiraEndInputText_short2,
.formJiraEndInputText_long,
.formJiraEndInputNum {
  padding: 12px 0;
  outline: none;
  resize: none;
  padding: 0.625em;
  border-radius: 16px;
  height: 40px;
  background-color: #f2f4ff;
  border: 1px solid #f2f4ff;
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 15px;
}
.terminationRadioInput {
  margin-right: 5px;
  flex-grow: 1;
  width: auto;
}

.formJiraEndInputText {
  width: 38%;
}

.formJiraEndInputText_short {
  width: 20%;
}

/* .formJiraEndInputText_short2 {
  width: 60%;
} */

.formJiraEndInputText_long {
  width: 100%;
  height: fit-content;
}
.formJiraEndInputNum {
  width: 10%;
}

.formJiraEndInputNumber {
  padding: 12px 0;
  outline: none;
  resize: none;
  padding: 0.625em;
  border-radius: 16px;
  height: 40px;
  background-color: #f2f4ff;
  display: inline-block;
  width: 30%;
}

.formArticleTextarea {
  outline: none;
  resize: none;
  height: 4em;
  padding: 1em;
  background-color: #f2f4ff;
  display: inline-block;
  width: calc(100% - 15px);
}

.formJiraEndLabel__required {
  color: #ff0000;
}

.formButtonsContainer {
  display: flex;
  gap: 1em;
}

.formJiraEndButton {
  border-radius: 100px;
  background: var(--color-blue);
  color: var(--color-white);
  padding: 10px 24px;
  border: none;
  width: max-content;
  cursor: pointer;
  text-align: center;
}

.infoButtonContainer {
  display: flex;
  align-items: center;
  margin-left: auto;
  text-decoration: none;
}

.formJiraEndSection_moreInfo {
  margin-left: auto;
}

.infoButton {
  background: var(--color-white);
  color: var(--color-blue);
  padding: 8px 20px;
  border: 1px solid var(--color-blue);
  border-radius: 100px;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
}

.formJiraEndPhone {
  margin-bottom: 30px;
}

.formJiraEndButton__Section {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  margin-top: 15px;
}

.formJiraEndButton.enabled {
  background: var(--color-blue);
  color: var(--color-white);
  cursor: pointer;
  opacity: 100%;
}

.formJiraEndButton.disabled {
  background: var(--color-blue);
  color: var(--color-white);
  cursor: pointer;
  opacity: 70%;
}

.formJiraEndButton input,
.formButtonsContainer input {
  width: 0.1px;
  height: 0.1px;
  cursor: pointer;
}

.secondaryButton {
  background-color: var(--color-white);
  border: 1px solid var(--color-blue);
  color: var(--color-blue);
  cursor: pointer;
}

.contactTextContainer-formJiraEnd {
  width: 100%;
  margin-top: 3em;
}

.contactTextContainer p a {
  color: var(--color-blue);
}
.contactMeJiraSectionButton {
  display: flex;
  justify-content: flex-end;
  margin-top: 2em;
  gap: 1em;
}

.formJiraEndLabel__comment {
  font-size: 14px;
  color: #9b9b9b;
  line-height: normal;
}

.formJiraEndCheckboxGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.formJiraEndCheckboxGroup_change {
  border: 1px solid #d1d5e4;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.formJiraEndRadioGroup {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.formJiraEndRadio {
  margin-left: 10px;
}

.formJiraEndRadioLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.formJiraEndCheckboxLabel {
  display: flex;
  align-items: center;
  gap: 10px;
}

.formJiraEndCheckboxLabel input[type='checkbox'] {
  margin-right: 8px;
}

.formJiraTemplateMessage {
  display: flex;
  align-items: center;
}

.formJiraTemplateMessageType {
  padding-top: 1em;
  padding-bottom: 1em;
  padding-right: 0.7em;
}

/* Círculo antes del párrafo */
.timeOutPopup__sectionText--text::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 5px;
  height: 5px;
  background-color: #000;
  border-radius: 50%;
  transform: translateY(-50%);
}

.timeOutPopup__sectionText--text {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
  line-height: 1.6;
  align-items: justify;
}

/* Estilo para las preguntas */
.timeOutPopup__sectionText--question {
  font-weight: bold;
  margin-bottom: 10px;
  padding-left: 0;
  align-items: justify;
}

/* Ajustar el ancho del pop-up */
.dislikePopup-content {
  max-width: 800px;
  padding: 20px;
  max-width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  padding: 20px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.dislikePopup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.formJiraEndSelect {
  padding: 10px;
  border: 1px solid #d1d5e4;
  border-radius: 4px;
  width: 40%;
  background: #fff;
  font-size: 16px;
}

/* Ajustar el espaciado entre los botones de sí y no */
.formArticleLabel div label {
  gap: 10px;
}

@media (max-width: 700px) {
  .formArticle__form {
    width: 80%;
  }
  .formJiraEndSection {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 500px) {
  .formArticle__form {
    width: 100%;
  }
}
