.header {
  background-color: var(--color-blue);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4em;
  padding: 0.188em 2em;
  position: sticky;
  top: 0;
  z-index: 2;
}

.peoplex__logo {
  display: flex;
  width: 9em;
  padding: 2em 1em;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.header__icons {
  display: flex;
  align-items: center;
  gap: 2em;
  list-style-type: none;
}

.header__icon--item {
  padding-top: 3px;
  width: 1, 25em;
  height: 1, 25em;
}

.header__icon--item-talent {
  padding-top: 3px;
  width: 1.8em;
  height: 1.8em;
}

.searcherHeader__container {
  display: flex;
  justify-content: center;
  padding-top: 3px;
  width: 1, 25em;
  height: 1, 25em;
}

.userAccount__container {
  display: flex;
  gap: 2em;
  align-items: center;
  list-style-type: none;
  cursor: pointer;
}

.user-icon {
  cursor: pointer;
  height: 1.7em;
  width: 1.7em;
}

/* Estilos para el input cuando NO está expandido */
.searcherHeader__input {
  display: none;
}

/* Estilos para el contenedor del input cuando está expandido */
.searcherHeader__container.expanded {
  width: 300px;
  padding: 0px 20px;
  height: 37px;
  background-color: var(--color-lilac);
  border-radius: 2.5em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.searcherHeader__container.expanded .lupa {
  display: none;
}

/* Estilos para el input cuando está expandido */
.searcherHeader__input.expanded {
  display: block;
  /* Mostrar el input cuando está expandido */
  outline: none;
  background-color: var(--color-lilac);
  border: none;
  font-size: 0.875em;
}

/* Estilos para el icono cuando está expandido */
.header__icon--item.expanded {
  display: none;
}

.header__greyIcon--item {
  display: none;
}

.header__greyIcon--item.expanded {
  display: flex;
  width: 1.25em;
}

.user__popup {
  position: absolute;
  top: 4em;
  width: 14.438em;
  right: 0;
  background-color: #f2f4fe;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.user__popup--close {
  padding: 0.5em 1em 0;
  text-align: end;
}

.user__popup--closeImg {
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
}

.user__popup--mail {
  color: #676d83;
  text-align: left;
  font-size: 0.813em;
  padding: 1.5em 1em;
  word-break: break-all;
}

.user__popup--logOut {
  padding: 1em 1em 1.5em;
  display: flex;
  gap: 0.75em;
  cursor: pointer;
}

.user__popup--logOutimg {
  height: 1.25em;
  width: 1.25em;
}

.user__popup--logOutbutton {
  color: #3363f0;
  border: none;
  background-color: none;
  font-size: 0.813em;
}

@media (max-width: 500px) {
  .user__popup--closeImg {
    width: 2em;
    height: 2em;
    cursor: pointer;
  }
}
