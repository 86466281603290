:root {
  --color-white: #ffffff;
  --color-black: #031a34;
  --color-blue: #06f;
  --color-lilac: #f2f4ff;
  --color-turquoise: #59c2c9;
  --color-dark-grey: #58617a;
  --color-light-grey: #dedfe4;
  --color-dark-lilac: #bcc0d8;
  --color-article-answer: #555;
  background-color: white;
}

@font-face {
  font-family: Telefonica Sans;
  src: url(../fonts/Telefonica\ Sans\ Regular.otf);
}
@font-face {
  font-family: 'Telefonica_Sans_Light';
  src: url('../fonts/Telefonica\ Sans\ Light.otf');
}
@font-face {
  font-family: 'Telefonica_Sans_Medium';
  src: url('../fonts/Telefonica\ Sans\ Medium.otf');
}