/* Estilos generales */
.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3em;
}
.ticket-list-container {
  display: flex;
}
.ticket-search-input {
  width: 250px;
}
.filter-sidebar {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.table-container {
  width: 100%;
  /* margin-left: -225px; */
  /*  margin: 20px auto; */
  margin-top: 10px;
  width: 100%;
  display: flex;
}
.pagination {
  display: flex;
  justify-content: center; /* Centra los botones de paginación */
  margin-top: 20px; /* Espacio superior */
}

.pagination button {
  background-color: #007bff; /* Color de fondo */
  color: white; /* Color del texto */
  border: none; /* Sin borde */
  border-radius: 4px; /* Bordes redondeados */
  padding: 8px 12px; /* Espaciado interno */
  margin: 0 5px; /* Espacio entre botones */
  cursor: pointer; /* Cambia el cursor al pasar el mouse */
  transition: background-color 0.3s; /* Transición suave */
}

.pagination button:hover {
  background-color: #0056b3; /* Color al pasar el mouse */
}

.pagination button:disabled {
  background-color: #cccccc; /* Color para botones deshabilitados */
  cursor: not-allowed; /* Cursor de no permitido */
}

.tickets-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-size: 12px;
}

.tickets-table th,
.tickets-table td {
  border-bottom: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.tickets-table th {
  background-color: #f2f2f2;
}

.tickets-table tr:hover {
  background-color: #f1f1f1;
}
.tickets-table td h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 0;
  display: block;
  text-align: left;
}
.tickets-table td p {
  margin: 0;
  padding: 0;
  display: block;
  text-align: left;
}

.especialTD {
  background-color: #f9f9f9;
  font-weight: 700;
  grid-column: span 9;
  padding: 10px;
  text-align: left;
  width: 100%;
  margin-bottom: 70px;
}
.margin-row td {
  height: 20px;
  background-color: transparent;
}

/* Prioridad (Pr) */
.priority-high {
  background-color: red;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.priority-medium {
  background-color: orange;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.priority-low {
  background-color: green;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

/* Buscador */

.historialTickets-search-input {
  background-image: url('../../../images/search.png'); /* Cambia esta ruta por la de tu imagen */
  background-repeat: no-repeat;
  background-position: 10px center; /* Ajusta la posición de la imagen */
  background-size: 20px; /* Ajusta el tamaño de la imagen */
  padding-left: 40px; /* Aumenta el padding para dar espacio al texto */
  height: 40px; /* Ajusta la altura si es necesario */
  border: 1px solid #ccc; /* Opcional: agrega un borde */
  border-radius: 4px; /* Opcional: agrega bordes redondeados */
}
.tickets-search-input {
  background-image: url('../../../images/search.png'); /* Cambia esta ruta por la de tu imagen */
  background-repeat: no-repeat;
  background-position: 10px center; /* Ajusta la posición de la imagen */
  background-size: 20px; /* Ajusta el tamaño de la imagen */
  padding-left: 40px; /* Aumenta el padding para dar espacio al texto */
  height: 40px; /* Ajusta la altura si es necesario */
  border: 1px solid #ccc; /* Opcional: agrega un borde */
  border-radius: 4px; /* Opcional: agrega bordes redondeados */
}

/* SIDE BAR */

.filter-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.filter-section-input {
  margin-right: 5px;
}

.filter-section-title {
  font-weight: bold;
  margin-top: 1em;
}
.filter-section-fields {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: 1px solid #ccc;
  margin-bottom: 20px;
  width: 100%;
}
.filter-section-label {
  margin-top: 1em;
}

@media (max-width: 700px) {
  .ticket-list-container {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .fticket-list-container {
    width: 100%;
  }
}
