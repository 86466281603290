
.custom-modal .modal-content {
    max-width: 400px; 
    margin: auto;
    font-family: 'Telefonica Sans', sans-serif; 
    border-radius: 24px; 
}

.btn-mantener {
    background-color: white;
    color: #0066FF; 
    border: 1px solid #0066FF;
}

.btn-mantener:hover {
    background-color: #0066FF;
    color: white; 
    cursor: pointer;
    opacity: 0.9;
}


.btn-delete {
    background-color: #E66C64 !important; 
    color: white; 
    border: none;
}

.btn-delete:hover {
    background-color: #cf2519 !important; 
    opacity: 0.85; 
    cursor: pointer;
}
