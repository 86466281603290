/* General Styles */
.formJiraChange {
  /*margin: 20px;*/
  padding: 20px;
  /* background-color: #f5f5f5;
	border-radius: 8px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.circle-icon-change {
  padding-right: 0.3em;
}

.formJiraChange__title {
  color: var(--color-blue);
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 40px;
}
.formJiraChange_section {
  margin-left: 2.3em;
}

/* Breadcrumb */
.jiraChangeForm__breadcrumb {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 2.5em;
  margin-top: 2em;
}

.jiraChangeForm__breadcrumb--title {
  color: #6e7894;
  font-size: 0.875em;
  line-height: 1.25em;
  font-weight: 700;
  text-decoration: none;
}

.jiraChangeForm__breadcrumb--text,
.jiraChangeForm__breadcrumb--text a {
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  color: #6e7894;
  font-size: 0.875em;
}

.jiraChangeForm_menuFormContainer {
  display: flex;
  flex-direction: row;
}

.jiraChangeForm_menu {
  gap: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  margin-left: -10px;
}

.jiraChangeForm_menu button {
  background-color: #fff;
  color: var(--color-black);
  padding: 15px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, color 0.3s;
}

.jiraChangeForm_menu button:hover {
  background-color: #f2f4ff;
}

.jiraChangeForm_menu button:active {
  background-color: #051051;
  color: var(--color-blue);
}
.jiraChangeForm_menu button:focus {
  outline: none;
  background-color: #f2f4ff;
  color: var(--color-blue);
}

/* Form Styles */
.formJiraChange_form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
  align-items: stretch;
}
.formJiraChangeInfoTittle {
  color: #031a34;
  font-size: 1.2em;
  font-weight: 100;
  margin-bottom: 20px;
}

.formJiraChangeSection {
  gap: 10px;
  margin-bottom: 20px;
}
.formJiraChangeSection_both {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
}
.formJiraChangeLabel_bothIndividual {
  width: 50%;
}
.formJiraChangeSectionRadio {
  display: flex;
  flex-direction: row;
}
.formJiraChangeSection_searchButton {
  display: flex;
  gap: 10px;
  margin-bottom: 2em;
  justify-content: flex-end;
}

.formJiraChangeLabel,
.formJiraChangeLabel_short,
.formJiraChangeLabel_short2,
.formJiraChangeLabel_Manager,
.formJiraChangeLabel_bothIndividual {
  line-height: 160%;
  color: #6c6c6c;
  margin-right: 10px;
  margin-bottom: 5px;
}
.formJiraChangeLabel {
  width: 100%;
}
.formJiraChangeLabelRadio {
  display: flex;
  align-items: baseline;
}

.formJiraChangeLabel_short2 {
  margin-left: 10px;
  width: 15%;
}

.formJiraChangeLabel_short {
  width: 45%;
}

.formJiraChangeRadio {
  align-items: flex-start;
  display: flex;
  margin-bottom: 15px;
}

.formJiraChangeInputText,
.formJiraChangeInputText_short,
.formJiraChangeInputText_Manager {
  padding: 12px 0;
  outline: none;
  resize: none;
  padding: 0.625em;
  border-radius: 16px;
  height: 40px;
  background-color: #f2f4ff;
  border: 1px solid #f2f4ff;
  display: inline-block;
}

.formJiraChangeInputText {
  width: 60%;
}

.formJiraChangeInputText_short {
  width: 50%;
}
.formJiraChangeInputText_Manager {
  width: 50%;
}

.formJiraChangeInputNumber {
  background-color: #f2f4ff;
  border: 1px solid #f2f4ff;
  border-radius: 16px;
  display: inline-block;
  height: 40px;
  outline: none;
  padding: 0.625em;
  resize: none;
  width: 30%;
}

.formJiraChangeSelect {
  padding: 10px;
  border: 1px solid #d1d5e4;
  border-radius: 4px;
  width: 100%;
  /*max-width: 300px; */
  background: #fff;
  font-size: 16px;
}

.formJiraChangeCheckboxGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.formJiraChangeCheckboxGroup_changes {
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.formJiraChangeCheckboxLabel {
  display: flex;
  align-items: center;
  gap: 10px;
}
.formJiraChangeRadioGroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.formJiraChangeRadio {
  margin-left: 10px;
  margin-right: 0.5em;
}
.formJiraChangeRadioLabel {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  gap: 10px;
}
.formJiraChangeLabelRadio {
  align-items: baseline;
  display: flex;
  width: 100%;
}
.formJiraChangeWarningMessage {
  color: #007bff;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.formJiraChangeLabel__required {
  color: red;
  font-size: 12px;
}
.errorBorder {
  border: 2px solid #007bff;
}
.prefilledBorder {
  border: 2px solid var(--color-blue);
}

/* Autosuggest styles */
.autosuggest-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.react-autosuggest__input {
  padding: 12px 0;
  outline: none;
  resize: none;
  padding: 0.625em;
  border-radius: 16px;
  height: 40px;
  background-color: #f2f4ff;
  border: 1px solid #f2f4ff;
  display: inline-block;
}
.react-autosuggest__container {
  width: 80%;
}

.react-autosuggest__suggestions-container--open {
  position: absolute;
  background-color: white;
  border: 1px solid #ffffff;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}
.employeeDetails {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-content: flex-start;
}

.react-autosuggest__suggestion {
  padding: 10px;
  cursor: pointer;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #007bff;
  color: white;
}

.formJiraChangeCheckboxGroup_change {
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

/* Feedback Popup */
.feedbackPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1050;
}

.feedbackPopup__message {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}

.feedbackPopup__buttons {
  display: flex;
  justify-content: flex-end;
}

.feedbackPopup__button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.feedbackPopup__button:hover {
  background-color: #0056b3;
}

.dislikePopup-content {
  width: 80%;
  max-width: 800px;
  padding: 20px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.formJiraChangeButton_section {
  display: flex;
  gap: 2em;
  flex-direction: row-reverse;
}

.formJiraChangeButton {
  border-radius: 100px;
  background: var(--color-blue);
  color: var(--color-white);
  padding: 10px 24px;
  border: none;
  width: max-content;
  cursor: pointer;
  text-align: center;
  width: -webkit-max-content;
  width: max-content;
}

.secondaryButton {
  background-color: var(--color-white);
  border: 1px solid var(--color-blue);
  color: var(--color-blue);
  cursor: pointer;
  border-radius: 100px;
  padding: 10px 24px;
  text-align: center;
  width: -webkit-max-content;
  width: max-content;
  margin-left: 10px;
}

.formJiraChangeButton.enabled {
  background: var(--color-blue);
  color: var(--color-white);
  cursor: pointer;
  opacity: 100%;
}

.formJiraChangeButton.disabled {
  background: var(--color-blue);
  color: var(--color-white);
  cursor: pointer;
  opacity: 70%;
  cursor: pointer;
}

.contactTextContainer {
  width: 100%;
  margin-top: 8em;
}

.contactTextContainer p a {
  color: var(--color-blue);
}

@media (max-width: 700px) {
  .formJiraChange_form {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .formJiraChange_form {
    width: 100%;
  }
}
