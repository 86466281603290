/* Media query para pantallas de portátiles de 17 pulgadas (1920px de ancho) */
@media only screen and (min-width: 1025px) and (max-width: 1599px) {
   .sidebar{
    width: 280px !important;
   }
   .main-card {
    margin-left: 150px !important;
    width: calc(100% - 300px); 
    margin: 0 auto; 
    }
}

  @media only screen and (min-width: 900px) and (max-width: 1025px) {
    .sidebar {
        width: 150px !important; 
        
    }

    .main-card {
        margin-left: 280px;
        margin-right: 10px; 
        width: calc(100% - 150px); 
       
    }

   
}
