.adminSidebar.collapsed ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70px;
}

.adminSidebar.collapsed .nav-item {
  text-align: center;
  font-size: 12px;
}

.toggle-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonContainer {
  margin: 10px;
  float: right;
}
.toggle-btn {
  height: 14px;
  float: right;
  cursor: pointer;
}
