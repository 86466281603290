.filter-container {
  overflow: hidden;
  transition: opacity 0.3s;
  transform: 0.3s;
  display: flex;
  align-items: center;
  margin-top: 1em;
  gap: 1em;
}
.filter-container.visible {
  max-height: 200px;
  border: 1px solid #f5f5f5;
  border-radius: 5px;
}
.contentButton {
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contentButton:hover {
  background-color: #e0e0e0;
}

.contentButton.selected {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.filter-container.hidden {
  display: none;
  border: none;
}
.subcategoriesSelect {
  width: fit-content;
  font-size: 12px;
  border: none;
  background-color: #f5f5f5;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
}
.subcategoriesInput {
  font-size: 12px;
  width: 170px;
  border: none;
  border-radius: 0 5px 5px 0;
}
.contentButton {
  border: none;
  /* color: blue; */
  padding: 5px 10px;
  /* border-radius: 20px; */
}
button:disabled {
  border-bottom: 1px solid black;
  color: black;
  background-color: #b4b3b3;
  /* font-weight: bold; */
}
.BD_content {
  width: 100%;
}
.BD_content img {
  width: 100%;
}
.adminPagination {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin: 20px 0;
}

.adminPagination {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.adminPagination button {
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #ddd;
  background-color: white;
}

.adminPagination button:disabled {
  background-color: #f5f5f5;
  cursor: auto;
}

.adminPagination .dots {
  padding: 5px 10px;
}
