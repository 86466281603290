.notFound__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}
.notFound__error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.notFound__errorNumber {
  color: #06f;
  text-align: center;
  font-family: Telefonica Sans;
  font-size: 80px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.notFound__errorDescription {
  color: #414b61;
  font-family: Telefonica Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.notFound__backButton {
  cursor: pointer;
  display: inline-flex;
  padding: 10px 24px;
  margin-top: 20px;
  align-items: flex-start;
  border-radius: 100px;
  background: #06f;
  color: var(--textButtonPrimary, #fff);
  text-align: center;
  font-family: Telefonica Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border: none;
}
