* {
  padding: 0;
  margin: 0;
  font-size: 100%;
  box-sizing: border-box;
  font-family: Telefonica Sans;
  font-weight: normal;
  margin-bottom: 0;
}
p {
  margin-bottom: 0;
}

.marginContainer {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 0 11em;
}
/* Pop up expirar sesión  */
@media (min-width: 2000px) {
  .marginContainer {
    padding: 0 21em 3em 21em;
    align-items: normal;
  }
}
@media (max-width: 2000px) {
  .marginContainer {
    padding: 0 20em 3em 20em;
    align-items: normal;
  }
}
@media (max-width: 1600px) {
  .marginContainer {
    padding: 0 16em 3em 16em;
    /* padding: 0 12em 3em 12em; */
    align-items: normal;
  }
}
@media (max-width: 1400px) {
  .marginContainer {
    padding: 0 10em 3em 10em;
    align-items: normal;
  }
}

@media (max-width: 1024px) {
  .marginContainer {
    padding: 0 5em 3em 5em;
    align-items: normal;
  }
}

@media (max-width: 500px) {
  .marginContainer {
    padding: 0 2em 2em 2em;
  }
}
