.card-mobile {
    width: 24rem !important;
    height: 6.5rem !important;
    cursor: pointer !important;
    border: none !important;
    margin-left: 10px;
   
  }

.Imagen-mobile {
  width: 70px !important;
  height: 70px !important;
  object-fit: cover !important; 
  background: #007bff !important;
  border-radius: 50% !important; 
  margin-right: 1rem !important;
  flex-shrink: 0;

}
  
  
.card-title-mobile{
    font-family: Telefonica Sans !important;

}
.card-text-mobile{
    font-family: Telefonica_Sans_Light !important;

}

/* Media query específica para las dimensiones del iPhone 12 Pro */
@media (max-width: 390px) and (max-height: 844px) {

  .card-mobile {
    width: 21rem !important;
    height: 6.5rem !important;
    cursor: pointer !important;
    border: none !important;
       
  }

  .Imagen-mobile {
    width: 65px !important;
    height: 65px !important;
    object-fit: cover !important; 
    background: #007bff !important;
    border-radius: 50% !important; 
    margin-right: 1rem !important;
    flex-shrink: 0;

  }
}

/* Media query específica para las dimensiones del iPhone XR */
@media (max-width: 414px) and (max-height: 896px) {

  .card-mobile {
    width: 23rem !important;
    height: 6.5rem !important;
    cursor: pointer !important;
    border: none !important;
      margin-left: 0px !important;
  }
  

  .Imagen-mobile {
    width: 65px !important;
    height: 65px !important;
    object-fit: cover !important; 
    background: #007bff !important;
    border-radius: 50% !important; 
    margin-right: 1rem !important;
    flex-shrink: 0;

  }
}
/* Media query específica para las dimensiones del iPhone 12 */
@media (max-width: 390px) and (max-height: 844px) {

  .card-mobile {
    width: 21.5rem  !important;
    height: 6.5rem !important;
    cursor: pointer !important;
    border: none !important;
      margin-left: 0px !important;
  }
  

  .Imagen-mobile {
    width: 65px !important;
    height: 65px !important;
    object-fit: cover !important; 
    background: #007bff !important;
    border-radius: 50% !important; 
    margin-right: 1rem !important;
    flex-shrink: 0;

  }
}
/* Media query específica para las dimensiones del iPhone 14 */
@media (max-width: 430px) and (max-height: 932px) {
  .card-mobile {
    width: 24rem  !important;
    height: 6.5rem !important;
    cursor: pointer !important;
    border: none !important;
      margin-left: 0px !important;
  }
  
}
/* Media query específica para las dimensiones del PIXEL7 */
@media (max-width: 412px) and (max-height: 915px) {
  .card-mobile {
    width: 23rem  !important;
    height: 6.5rem !important;
    cursor: pointer !important;
    border: none !important;
      margin-left: 0px !important;
  }
  
}



