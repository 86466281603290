.featured__articles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 3em;
}

.featured__articles--breadcrumb {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 2.5em;
}

.featured__articles--title {
  color: #6e7894;
  font-size: 0.875em;
  line-height: 1.25em;
}

.cirle-icon {
  width: 0.625rem;
  height: 0.625rem;
  color: var(--color-blue);
}

.featured__boxes {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5em;
  justify-content: space-between;
  width: 100%;
}

.featured__contentbox {
  width: 12.5em;
  display: flex;
  cursor: pointer;
  height: 12.5em;
}

.featured__contentbox__imgbox {
  display: flex;
  width: 100%;
  position: relative;
}

.featured__contentbox__imgbox--img {
  filter: brightness(60%);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  object-fit: cover;
  object-position: left;
  border-radius: 0 0 0.5em 0;
}

.featured__contentbox__imgbox--img:hover {
  opacity: 0.7;
  transition: all 0.35s ease-in;
}
.featured__contentbox__imgbox--text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 1em 1em 1.5em;
  width: 85%;
  z-index: 1;
}

.featured__contentbox__iconbox {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 2.75em;
  height: 2.75em;
  background-color: var(--color-blue);
  border-radius: 0rem 0rem 0.5rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconWidth {
  width: 1em;
  display: flex;
  flex-direction: row-reverse;
  overflow: hidden;
}

.featured__contentbox__iconbox--iconArrowRight {
  width: 1em;
  transition: transform 0.3s;
}

.featured__contentbox__iconbox--iconArrowRight:hover {
  background-image: url(../../images/arrow-right.svg);
  transform: translateX(100%);
}

.featured__contentbox__textbox {
  width: 50%;
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.featured__contentbox__textbox--categorie {
  color: var(--color-white);
  font-size: 0.7em;
  line-height: 24px;
}

.featured__contentbox__textbox--title {
  color: var(--color-white);
  font-weight: 600;
  font-size: 0.8em;
  line-height: 1.5;
}

.featured__contentbox__textbox--paragraph {
  color: #6e7894;
  font-size: 1em;
  text-align: justify;
}

@media (max-width: 1450px) {
  /* .featured__contentbox {
    width: 27.5em;
  } */

  .featured__contentbox__textbox {
    padding: 1em 0.875em;
  }
}

@media (max-width: 1024px) {
  .featured__contentbox__textbox--title,
  .featured__contentbox__textbox--paragraph {
    font-size: 0.875em;
  }

  .featured__contentbox__textbox {
    height: 100%;
  }
}

@media (max-width: 1200px) {
  .featured__boxes {
    gap: 20px;
  }

  /* .featured__contentbox {
    width: 100%;
  } */
}
@media (max-width: 1200px) {
  .featured__boxes {
    justify-content: flex-start;
    gap: 8px;
  }
}

@media (max-width: 600px) {
  .featured__boxes {
    gap: 3em;
  }

  .featured__contentbox {
    flex-direction: column;
    width: 100%;
    height: max-content;
  }

  .featured__contentbox__imgbox {
    width: 100%;
    height: 50%;
  }

  .featured__contentbox__textbox {
    width: 100%;
    padding: 1em 0;
  }

  /* .featured__contentbox__textbox--title {
    font-size: 18px;
    font-weight: 700;
  } */
}
