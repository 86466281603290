.cluster-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .circles-container-card {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .circle-card {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #007bff;
    display: flex;
    align-items: center;
    font-size: 10px;
    justify-content: center;
  }
  
  .arrow-circle-card {
    margin-right: 1px !important;
    width: 25px !important;
  }
  /* Media query para pantallas de portátiles de 17 pulgadas (1920px de ancho) */
@media only screen and (min-width: 1600px) and (max-width: 1920px) {

    
}