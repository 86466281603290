.articleList {
  padding: 5em 0;
}

.articleList__breadcrumb--container {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 2.5em;
}

.articleList__breadcrumb--title {
  color: #6e7894;
  font-size: 0.875em;
  line-height: 1.25em;
  font-weight: 700;
  text-decoration: none;
}

.articleList__breadcrumb--text a {
  text-decoration: none;
  color: #6e7894;
  line-height: 1.25em;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: none;
}

.articleList__breadcrumb--text {
  color: #6e7894;
  font-size: 0.875em;
  line-height: 1.25em;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
}

/* Estilos cuando la sección no está expandida */
.articleList__content {
  gap: 1em;
  display: flex;
  flex-direction: column;
}