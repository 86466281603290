/* Estilos para el contenedor principal */
.myFolderPage {
	padding: 0em 0;
	width: 100%;
	max-width: 2000px;
	margin: 0 auto;
  }
  
  .myFolderPage__breadcrumb {
	display: flex;
	align-items: center;
	margin-bottom: 2.5em;
  }
  
  .myFolderPage__breadcrumb--title {
	color: #6e7894;
	font-size: 0.875em;
	line-height: 1.25em;
	font-weight: 700;
	text-decoration: none;
  }
  
  .myFolderPage__breadcrumb--text,
  .myFolderPage__breadcrumb--text a {
	font-weight: 400;
	text-transform: uppercase;
	text-decoration: none;
	color: #6e7894;
	font-size: 0.875em;
  }
  
  .myFolderPage__content a {
	text-decoration: none;
  }
  
  .myFolderPage__title {
	color: var(--color-blue);
	font-size: 1.375em; 
	margin-bottom: 2.5em;
	margin-top: 1.25em;
  }
  
  .myFolderPage__options {
	display: flex;
	flex-wrap: wrap;
  }
  
  .myFolderPage__option {
	width: 31.5%; 
	height: 14em;
	border: 1px solid #d1d5e4;
	position: relative;
	border-radius: 0.5em;
	background: white; 
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
	transition: opacity 0.3s, transform 0.3s; 
  }
  
  .myFolderPage__option:not(:first-child) {
	/*opacity: 0.5;*/ 
  }
  
  .myFolderPage__option:hover {
	opacity: 1; 
	transform: translateY(-5px); 
  }
  
  .myFolderPage__buttonDisabled {
	pointer-events: none; 
  }
  
  .myFolderPage__imageContent {
	height: 3em;
	width: 3em;
	border-radius: 90%;
	background-color: #eaf0ff;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1em;
	margin-left: 1.5em;
  }
  
  .myFolderPage__image {
	height: 50%;
	width: 50%;
	object-fit: contain;
  }
  
  .myFolderPage__textContent {
	padding: 1em;
  }
  
  .myFolderPage__groupTitle {
	color: var(--color-blue);
	font-size: 17px; 
	font-weight: 500; 
	line-height: 28px; 
	margin-left: 2em;
	margin-top: 1em;
  }
  
  .myFolderPage__description {
	color: #6E7894;
	font-size: 12px;
	font-weight: 400;
	line-height: 24px;
	margin-left: 2em;
  }
  
  .myFolderPage__soon {
	color: var(--color-blue);
	font-size: 1em;
	text-align: right;
	margin-right: 2em;
	margin-top: 2em;
  }
  
  .myFolderPage__arrowButton {
	position: absolute;
	bottom: 0px;
	right: 0px;
	background-color: var(--color-blue);
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
	border: none;
	cursor: pointer;
	border-bottom-right-radius: 0.5em;
  }
  
  .myFolderPage__arrow {
	width: 17px;
	height: 17px;
  }
  
  .myFolderPage__buttonDisabled {
	opacity: 0.5;
  }

  .myFolderPage__historyButton {
	background: #fff;
	border: 1px solid #d1d5e4;
	border-radius: .5em;
	box-shadow: 0 2px 4px #0000001a;
	height: 3em;
	position: relative;
	transition: opacity .3s, transform .3s;
	margin-top: 20px;
	width: 14em;
	display: flex;
	align-items: center;
	justify-content: center;
  }

  .myFolderPage__historyButton:hover {
	opacity: 1;
	transform: translateY(-5px);
  }
  .myFolderPage__historyLink {
	text-decoration: none;
    color: #06f;
	font-size: 20px;
    font-weight: 500;
    line-height: 28px;
  }

  /* Ajustes para pantallas grandes (1200px a 2000px) */
  @media (min-width: 1200px) and (max-width: 2000px) {
	.myFolderPage__options {
	  gap: 1rem;
	}
  
	.myFolderPage__option {
	  width: calc((100% / 4) - 1rem); 
	}
  }
  
  /* Ajustes para pantallas medianas (900px a 1199px) */
  @media (min-width: 900px) and (max-width: 1199px) {
	.myFolderPage__options {
	  width: 100%;
	  gap: 1em;
	}
  
	.myFolderPage__option {
	  width: calc((100% / 3) - 1rem); 
	}
  
	.myFolderPage__soon {
	  font-size: 1em;
	  text-align: right;
	  margin-right: 2.5em;
	  margin-top: 3.5vh;
	}
  }
  
  /* Ajustes para tablets (700px a 899px) */
  @media (min-width: 700px) and (max-width: 899px) {
	.myFolderPage {
	  padding: 2em 0;
	  margin: 0 1rem;
	}
  
	.myFolderPage__options {
	  display: flex;
	  flex-wrap: wrap;
	  gap: 1rem;
	  margin: 0;
	}
  
	.myFolderPage__option {
	  width: calc((100% / 2) - 1rem); 
	}
  
	.myFolderPage__soon {
	  font-size: 1em;
	  text-align: right;
	  margin-right: 2em;
	  margin-top: 2em;
	}
  
	.myFolderPage__arrowButton {
	  width: 30vh;
	  height: 20vh;
	  background-color: transparent;
	  justify-content: unset;
	  align-items: unset;
	}
  
	.myFolderPage__arrow {
	  display: none;
	}
  }
  
  /* Ajustes para móviles grandes (500px a 699px) */
  @media (min-width: 500px) and (max-width: 699px) {
	.myFolderPage {
	  padding: 2em 0;
	  margin: 0 1rem;
	}
  
	.myFolderPage__options {
	  display: flex;
	  flex-wrap: wrap;
	  gap: 1rem;
	  margin: 0;
	}
  
	.myFolderPage__option {
	  width: calc((100% / 2) - 1rem); 
	}
  
	.myFolderPage__soon {
	  font-size: 1em;
	  text-align: right;
	  margin-right: 2em;
	 margin-top: 1em;
	}
  
	.myFolderPage__arrowButton {
	  width: 30vh;
	  height: 20vh;
	  background-color: transparent;
	  justify-content: unset;
	  align-items: unset;
	}
  
	.myFolderPage__arrow {
	  display: none;
	}
  }
  
  /* Ajustes para móviles pequeños (menos de 500px) */
  @media (max-width: 499px) {
	.myFolderPage {
	  padding: 2em 0;
	  margin: 0 1rem;
	}
  
	.myFolderPage__options {
	  display: flex;
	  flex-wrap: wrap;
	  gap: 1rem;
	  margin: 0;
	}
  
	.myFolderPage__option {
	  width: calc(100% - 1rem); 
	  height: 15%;
	}
  
	.myFolderPage__soon {
	  font-size: 1em;
	  text-align: right;
	  margin-right: 2em;
	  margin-top: 2em;
	}
  
	.myFolderPage__arrowButton {
	  width: 30vh;
	  height: 20vh;
	  background-color: transparent;
	  justify-content: unset;
	  align-items: unset;
	}
  
	.myFolderPage__arrow {
	  display: none;
	}
  }
  