/* Nóminas */
.payroll_section {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1.5em;
  }
  
  .payroll_container {
	width: 100%;
  }
  
  .payroll_actions {
	display: flex;
	justify-content: space-between;
  }
  
  .payroll_actions-download {
	color: var(--color-blue);
	border: 1px solid var(--color-blue);
	border-radius: 25px;
	background-color: white;
	cursor: pointer;
	padding: 10px 20px;
  }
  
  .payroll_actions-dateFilter {
	width: 350px;
	display: flex;
  }
  .select-button.month-option{
	margin-right: 25px;
  }
  
  .select-button {
	display: block;
	width: 100%;
	height: 2.5em;
	font-size: 1em;
	font-weight: 700;
	line-height: 1.1;
	padding: 0.313em 1.25em;
	color: #4d4e50;
	border: 1.5px solid #e8e9ec;
	border-radius: 3px;
	background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140"><polyline points="10,50 70,110 130,50" style="fill:none;stroke:%238B8C8F;stroke-width:15;stroke-linecap:round"/></svg>')
	  no-repeat right 15px center;
	background-size: 0.625em;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	cursor: pointer;
	margin: 0px;
	border-radius: 20px;
  }
  .text-primary{
	color: #007bff;
  }
  
  .select-button:focus {
	outline: none;
	border-color: #007bff;
  }
  
  .table-header {
	border-bottom: 1px solid #6e7894;
  }
  .invoice-table {
	width: 100%;
	border-collapse: collapse; /* Evita el espacio entre bordes de celdas */
	text-align: center;
  }
  
  .invoice-table thead tr.main-item {
	border-bottom: 2px solid black;
  }
  
  .invoice-table tbody tr {
	border-bottom: 1px solid #d1d5e4;
  }
  
  .invoice-table th,
  .invoice-table td {
	padding: 1em;
  }
  
  .checkbox {
	width: 10%;
  }
  
  .name {
	width: 60%;
  }
  
  .date {
	width: 30%;
  }
  
  .mainItem_name,
  .mainItem_date {
	font-weight: bold;
  }
  