.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.popup-content {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 40%;
}
.timeOutPopup__sectionTitle {
  padding: 1.25em 1.875em 0;
  border-radius: 10px 10px 0 0;
}
.timeOutPopup__sectionTitle--title {
  font-size: 1.75em;
}
.timeOutPopup__sectionText {
  padding: 1.25em 1.875em;
  display: flex;
  flex-direction: column;
  gap: 0.625em;
}
.timeOutPopup__sectionText--text {
  color: #676d83;
  line-height: 160%;
}
.timeOutPopup__sectionButtons {
  display: flex;
  padding: 20px;
  justify-content: right;
  gap: 25px;
}
.timeOutPopup__sectionButtons--button {
  padding: 0.625em 0.938em;
  border: none;
  border-radius: 30px;
  cursor: pointer;
}
.stay {
  background-color: var(--color-blue);
  color: var(--color-white);
  font-size: 0.875em;
}
.cancel {
  font-size: 0.875em;
  border: 1px solid var(--color-blue);
  background-color: var(--color-white);
  color: var(--color-blue);
}
@media (max-width: 700px) {
  .popup-content {
    width: 70%;
  }
}
