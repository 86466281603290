.myFolderPage {
  padding: 0em 0;
  overflow-y: hidden;
}

.myFolderPage__breadcrumb {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 2.5em;
  margin-top: 2em;
}

.myFolderPage__breadcrumb--title {
  color: #6e7894;
  font-size: 0.875em;
  line-height: 1.25em;
  font-weight: 700;
  text-decoration: none;
}

.myFolderPage__breadcrumb--text,
.myFolderPage__breadcrumb--text a {
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  color: #6e7894;
  font-size: 0.875em;
}

.myFolderSection__title {
  color: var(--color-blue);
  font-size: 28px;
  font-weight: 400;
  margin-left: 600px;
}

.myFolderSection_content {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.myFolderSection_container {
  display: flex;
  width: 100%;
}

.myFolderSection_menu {
  flex: 1;
  width: 165px;
  height: 260px;
  font-size: 16px;
}

.myFolderSection_menu ul {
  list-style-type: none;
  padding: 0;
}

.myFolderSection_menu li {
  margin-bottom: 12px;
}

.myFolderSection_menu a {
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 10px;
}

.myFolderSection_payroll,
.myFolderSection_beneficts,
.myFolderSection_retentions {
  /* opacity: 0.25; */
  text-decoration: none;
  color: black;
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
}

.myFolderSection_disabledLink {
  display: flex;
  pointer-events: none;
  /* opacity: 0.5; */
}

.myFolderSection_menu a.active,
.myFolderSection_certificate:hover {
  font-weight: bold;
  border-radius: 4px;
  background-color: #f2f4ff;
  color: var(--color-blue);
  cursor: pointer;
}

.menu-icon {
  margin-right: 10px;
  width: 16px;
  height: 16px;
}

.myFolderSection_components {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 80%;
  height: 100%;
  margin-left: 2em;
}
.formJiraEndButton__section {
  display: flex;
  justify-content: flex-end;
  gap: 1em;
}

@media (max-width: 1200px) {
  .myFolderSection_dissabledLink {
    font-size: 15px;
  }

  .menu_icon {
    width: 15px;
    height: 15px;
  }
}
@media (max-width: 1024px) {
  .myFolderSection_dissabledLink {
    font-size: 14px;
  }

  .menu_icon {
    width: 14px;
    height: 14px;
  }
}

@media (max-width: 768px) {
  .myFolderSection_dissabledLink {
    font-size: 12px;
  }

  .menu_icon {
    width: 12px;
    height: 12px;
  }
}

@media (max-width: 480px) {
  .myFolderSection_dissabledLink {
    font-size: 10px;
  }

  .menu_icon {
    width: 10px;
    height: 10px;
  }
}
