.subcategoriesPage {
  padding: 5em 0;
}

.subcategories__breadcrumb {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 2.5em;
}

.subcategories__breadcrumb--title {
  color: #6e7894;
  font-size: 0.875em;
  line-height: 1.25em;
  font-weight: 700;
  text-decoration: none;
}

.subcategories__breadcrumb--text {
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  color: #6e7894;
  font-size: 0.875em;
}

.subcategories__title {
  color: var(--color-blue);
  font-size: 1.5em;
  margin-bottom: 2.188em;
}

.subcategories__links {
  text-decoration: none;
}

.articlesContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 1.25em;
  /* justify-content: space-between; */
}

.subcategories__articlebox {
  display: flex;
  flex-direction: column;
  /* width: 16.75em; */
  width: 14em;
  /* gap: 5px; */
  border-radius: 8px;
  border: 1px solid #d1d5e4;
  cursor: pointer;
  /* flex: 1; */
}

.articlebox__imageContainer {
  /* height: 10em; */
  width: auto;
}

.articlebox__img {
  width: 100%;
  border-radius: 8px 8px 0 0;
}

.articlebox__textContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  padding: 1em;
  /* height: 12em; */
  height: 7em;
  overflow: hidden;
}

.articlebox__textContainer__name {
  font-size: 1.125em;
  color: var(--color-black);
}

.articlebox__textContainer--description {
  color: #6e7894;
  font-size: 1em;
  line-height: 150%;
  text-decoration-line: none;
}
/* 
@media (max-width: 900px) {
  .articlesContainer {
    justify-content: space-around;
    gap: 1em;
  }
}
@media (min-width: 1880px) {
  .subcategories__articlebox {
    width: 18em;
  }
}
@media (min-width: 2000px) {
  .subcategories__articlebox {
    width: 22em;
  }
}

@media (max-width: 1470px) {
  .subcategories__articlebox {
    width: 18em;
  }
}
@media (max-width: 1270px) {
  .subcategories__articlebox {
    width: 18em;
  }
}
@media (max-width: 800px) {
  .articlesContainer {
    gap: 1.25em;
  }
}
@media (max-width: 600px) {
  .subcategories__articlebox {
    width: 11.563em;
    /* height: 19.68em; */
/* }
  .articlebox__textContainer__name {
    font-size: 1.125em;
  }
  .articlebox__textContainer--description {
    font-size: 0.75;
  }
}
@media (max-width: 400px) {
  .articlesContainer {
    justify-content: space-around;
  }
  .subcategories__articlebox {
    width: 9.375em;
    /* height: 14.375em; */
/* }
  .articlebox__textContainer {
    height: auto;
  }
  .articlebox__textContainer__name {
    font-size: 1.125em;
  }
  .articlebox__imageContainer {
    height: 6.375em;
  }
}  */

@media (max-width: 2000px) {
  .subcategories__articlebox {
    width: 15.5em;
  }
}

@media (max-width: 1700px) {
  .subcategories__articlebox {
    width: 12em;
  }
}

@media (max-width: 1400px) {
  .subcategories__articlebox {
    width: 12.5em;
  }

  .articlesContainer {
    gap: 1.25em 4em;
    justify-content: flex-start;
  }
}

@media (max-width: 1200px) {
  .subcategories__articlebox {
    width: 14em;
  }
}

@media (max-width: 768px) {
  .subcategories__articlebox {
    width: 10em;
  }
}
