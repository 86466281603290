.btnInfo-exit, .btnInfo-download, .btnInfo-left, .btnInfo-right{
    cursor: pointer;
}

.infoComp{
    height: auto;
    max-height: auto;
}
.logo-container {
  height: 80px; 
}
.btnInfo-download{
  height: auto;
  width: auto;
}

.logo-telefo {
  width: 200px !important;
  height: 200px !important;
  margin-right: 50px !important;
  
}

.btnInfo-exit{
  width: 30px !important;
  height: 30px !important;
}

.download-container{
  margin-left: 20px !important;
}


@media only screen and (min-width: 900px) and (max-width: 1025px) {
  .infoComp {
    height: 100%;
    max-height: 100%;
    font-size: 0.55rem; 
    margin-top: 0rem !important;
  }  

  /*flechas back and go */
  .d-flex.justify-content-center svg {
    width: 12px; 
    height: 12px;

  }

  .btnInfo-left, .btnInfo-right {
    margin-top: 25px;
    width: 12px !important;
    height: 12px !important;
  }

  .btnInfo-exit {
    width: 15px !important; 
    height: 25px !important ;
    margin-left: 0.5rem !important;
    margin-top: 0.5rem !important;
  }

  .photo-container {
    width: 55px  !important; 
    height: 55px  !important;
  }
  .btnInfo-download{
    width: 30px  !important; 
    height: 30px  !important;
  }
  
  

  .info-year-ssff {
    font-size: 0.75rem !important;
  }
  .datefinal-exp{
    margin-left: 1.4rem !important;
  }
  
  .clock-icon, .location-icon, .person-icon {
    width: 15px; 
    height: 15px;
  }

  .talent-subtitle,.lang-subtitle {
    font-size: 0.8rem !important; 
  }
  


  .logo-telefo {
    width: 160px !important; 
    height: 160px !important; 
    margin-right: 20px !important; 
    transform: translateY(-45px) !important;
  }
 

}

/* Para pantallas grandes (laptops y pantallas medianas) - MI laptop */
@media only screen and (min-width: 1025px) and (max-width: 1599px) {
  .infoComp {
    height: 100vh;
    max-height: 100vh;
    font-size: 0.7rem;

  }  
  .infoComp .arrow-container-info{
    margin-top: 15px !important;
  }

   .d-flex.justify-content-center svg {
    width: 15px;
    height: 15px;
    
  }
  .btnInfo-exit{
    width: 20px !important;
    height: 20px !important;
  }
  
  .photo-container {
    width: 90px  !important; 
    height: 90px  !important;
    margin-left: 0.5rem !important;
  }
 
  
  .info-year-ssff {
    font-size: 0.8rem !important;
  }

  .datefinal-exp{
    margin-left: 1.7rem !important;
  }

  .btnInfo-left, .btnInfo-right {
    width: 18px !important;
    height: 18px !important;
    
  }
  .clock-icon,.location-icon,.person-icon{
    width: 20px;
    height: 20px;
    
  }
  .talent-subtitle,.lang-subtitle {
    font-size: 0.9rem !important; 
  }

  
  
  .logo-telefo {
    width: 180px !important; 
    height: 180px !important; 
    margin-right: 4px !important; 
    transform: translateY(-55px) !important;
  }
  

}

/*New Query*/
@media only screen and (min-width: 1600px) and (max-width: 2560px) {
  .infoComp {
    height: 100%;
    max-height: 100%;
    font-size: 1.1rem;
    padding: 1rem;
   
  }

  .d-flex.justify-content-center svg {
    width: 20px; 
    height: 25px; 
  }

  .btnInfo-exit {
    width: 25px; 
    height: 40px !important; 
  }

  .photo-container {
    width: 120px !important;
    height: 120px !important;
  }

  .photoComponent {
    width: 120px !important;
    height: 120px !important;
  }

  
  .btnInfo-left, .btnInfo-right {
    width: 25px !important;
    height: 25px !important;
    
  }

  .clock-icon, .location-icon, .person-icon {
    width: 25px; 
    height: 25px; 
    
  }
  .datefinal-exp{
    margin-left: 30px !important;
  }

  .talent-subtitle {
    font-size: 18px !important; 
  }

  .lang-subtitle {
    font-size: 18px !important; 

  }

  .logo-telefo {
    width: 250px !important; 
    height: 250px !important;
    padding-bottom:40px ;
  }
}
