/* Loading Spiner */
.loader {
  width: fit-content;
  font-size: 1em;
  color: var(--color-blue);
  background: linear-gradient(currentColor 0 0) 0 100%/0% 3px no-repeat;
  animation: l2 2s linear infinite;
  margin-right: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader:before {
  content: 'Generando ticket...';
}
.assistancePopup__option {
  display: flex;
  align-items: center;
  gap: 10px; /* Espacio entre el radio y el label */
}

.assistancePopup__textOptions--label {
  display: flex;
  flex-direction: column;
}

.assistancePopup__textOptions--desc {
  font-size: 0.85em; /* Ajuste del tamaño de la descripción si es necesario */
  margin: 4px 0 0 0;
  color: #666; /* Ajusta el color según el diseño */
}
.assistancePopupPaises {
  display: flex;
  justify-content: center;
  align-items: center;
  position: inherit;
  height: 60%;
}

@keyframes l2 {
  to {
    background-size: 100% 3px;
  }
}

.formArticleButton__section {
  display: flex;
  gap: 2em;
  align-items: center;
}
.contactUsButtonSection {
  position: fixed;
  bottom: 3em;
  right: 3em;
}

.contactUsButton {
  background-color: var(--color-blue);
  padding: 10px 24px;
  border-radius: 100px;
  font-size: 1em;
  color: var(--color-white);
  border: none;
  cursor: pointer;
}

.contactUsButton:hover {
  opacity: 0.7;
  transition: all 0.35s ease-in;
}

/* Pop up de Asistencia al Manager */

.assistancePopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
.timeOutPopup__sectionText--text a {
  color: var(--color-blue);
  cursor: pointer;
  text-decoration: none;
}
.assistancePopup__content {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  width: 37.5em;
  padding: 2.5em;
  display: flex;
  flex-direction: column;
}
.employeeContent {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.assistancePopup__titleSection {
  display: flex;
  justify-content: space-between;
}

.assistancePopup__textSection {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.assistancePopup__title {
  color: var(--color-black);
  font-size: 2.188em;
}

.assistancePopup__message {
  margin-top: 1em;
  color: #414b61;
  font-size: 1em;
  line-height: 160%;
}
.employeeMessage {
  margin-top: 0;
}
.assistancePopup__textOptions form input {
  margin-right: 1em;
}

.assistancePopup__textOptions--label {
  color: var(--color-black);
  font-size: 1em;
  line-height: 160%;
}

.assistancePopup__textOptions--desc {
  color: #676d83;
  font-size: 0.875em;
  font-style: italic;
  font-weight: 300;
  line-height: 160%;
  margin-left: 2em;
  margin-bottom: 1em;
}

.assistancePopup__fileSection {
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  gap: 1em;
}
.assistancePopup__fileSection--fileName {
  color: #676d83;
}

.assistancePopup__fileSection--deleteFileIcon {
  cursor: pointer;
  margin-top: 0.35em;
  height: 0.75em;
  width: 0.75em;
}
.assistancePopup__fileSection__fileSelected {
  display: flex;
  flex-direction: row;
  gap: 0.75em;
}
.assistancePopup__buttonSection {
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
  gap: 1em;
}

.assistancePopup__uploadFiles {
  background-color: #f6f6f6;
  border: 1.5px solid var(--color-blue);
  padding: 0.5em;
  border-radius: 50%;
  line-height: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.assistancePopup__uploadFiles label {
  cursor: pointer;
}

.assistancePopup__uploadFiles--input {
  width: 0.1px;
  height: 0.1px;
}

.assistancePopup__closeButton {
  width: 6em;
  border-radius: 100px;
  background-color: var(--color-blue);
  color: var(--color-white);
  padding: 0.75em 1.5em;
  border: none;
  opacity: 70%;
}

.assistancePopup__closeButton.enabled {
  width: 6em;
  border-radius: 100px;
  background-color: var(--color-blue);
  color: var(--color-white);
  padding: 0.75em 1.5em;
  border: none;
  cursor: pointer;
  opacity: 100%;
}

.assistancePopup__feedbackText {
  width: 100%;
  padding: 1em;
  border: none;
  border-radius: 10px;
  background-color: #f6f6f6;
  height: 9em;
  outline: none;
  resize: none;
}

.dislikePopup__sectionText--text::placeholder {
  color: #414b61;
  font-size: 1em;
}

@media (max-width: 700px) {
  .assistancePopup__content {
    width: 60%;
  }
}

@media (max-width: 500px) {
  .contactUsButtonSection {
    display: none;
  }
}

@media (max-width: 400px) {
  .assistancePopup__title {
    font-size: 1.625em;
  }
}
