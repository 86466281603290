.mainPanel {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5em 0;
}

.applications__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  gap: 2em 1em;
}

.appItem__container {
  position: relative;
  height: 14em;
  width: 14em;
  border: 1px solid #d1d5e4;
  cursor: pointer;
  transition: opacity 0.3s, transform 0.3s;
  box-shadow: 0 2px 4px #0000001a;
  display: flex;
  flex-direction: column;
  border-radius: 0.5em;
  padding: 1em;
  text-decoration: none;
}

.appItem__info {
  padding: 1em;
  height: 70%;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.appItem__info--name {
  font-size: 20px;
  color: var(--color-blue);
  font-weight: 700;
}

.appItem__info--icon {
  width: 2.5em;
  height: 2.5em;
}

.appItem__info--desc {
  font-size: 1em;
  color: #6e7894;
}

.featured__contentbox__iconbox {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 2.75em;
  height: 2.75em;
  background-color: var(--color-blue);
  border-radius: 0rem 0rem 0.5rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.featured__contentbox__iconbox--iconArrowRight {
  width: 1em;
  transition: transform 0.3s;
}
