/* Pop up */
@keyframes popup {
    0% {
        top: -100%;
    }

    100% {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

/* Sombra fondo */
.login-shade {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
}

.login-shade.active {
    display: block;
}

/* Pop up legal  */
.legal-popup {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1em;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    width: 50em;
    border-radius: 0.25rem;
    z-index: 2;
    animation: popup 0.8s ease forwards;
}

.legal-popup.active {
    display: block;
}