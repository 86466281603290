#load {
    position: absolute;
    width: 600px;
    height: 36px;
    left: 50%;
    top: 40%;
    margin-left: -300px;
    overflow: visible;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
  }
  
  #load div {
    position: absolute;
    width: 20px;
    height: 36px;
    opacity: 0;
    font-family: Helvetica, Arial, sans-serif;
    animation: move 2s linear infinite;
    transform: rotate(180deg);
    color: #0066FF;
  }
  
  #load div:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  #load div:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  #load div:nth-child(4) {
    animation-delay: 0.6s;
  }
  
  #load div:nth-child(5) {
    animation-delay: 0.8s;
  }
  
  #load div:nth-child(6) {
    animation-delay: 1s;
  }
  
  #load div:nth-child(7) {
    animation-delay: 1.2s;
  }
  
  @keyframes move {
    0% {
      left: 0;
      opacity: 0;
    }
  
    35% {
      left: 41%;
      transform: rotate(0deg);
      opacity: 1;
    }
  
    65% {
      left: 59%;
      transform: rotate(0deg);
      opacity: 1;
    }
  
    100% {
      left: 100%;
      transform: rotate(-180deg);
      opacity: 0;
    }
  }
  