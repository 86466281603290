/* General Styles */
.formJiraNew {
  /*margin: 20px;*/
  padding: 20px;
  /* background-color: #f5f5f5;
	border-radius: 8px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.formJiraNew__title {
  color: var(--color-blue);
  font-size: 28px;
  font-weight: 400;
  margin-bottom: 40px;
}

/* Breadcrumb */
.jiraNewForm__breadcrumb {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 2.5em;
  margin-top: 2em;
}

.jiraNewForm__breadcrumb--title {
  color: #6e7894;
  font-size: 0.875em;
  line-height: 1.25em;
  font-weight: 700;
  text-decoration: none;
}

.jiraNewForm__breadcrumb--text,
.jiraNewForm__breadcrumb--text a {
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  color: #6e7894;
  font-size: 0.875em;
}

/* Form Styles */
.formJiraNew_form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 80%;
  align-items: stretch;
}

.formJiraNewSection {
  gap: 10px;
  margin-bottom: 20px;
}
.formJiraNewSection_both {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
  padding-bottom: 1em;
}
.formJiraNewSection_bothIndividual {
  width: 48%;
}

.formJiraNewSection_three {
  align-items: flex-end;
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}
.formJiraNewSection_one {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}

.formJiraNewSection_searchButton {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: flex-end;
}

.formJiraNewLabel,
.formJiraNewLabel_short,
.formJiraNewLabel_short2 {
  line-height: 160%;
  color: #6c6c6c;
  margin-bottom: 10px;
  width: 90%;
}
.formJiraNewLabel_three {
  line-height: 160%;
  color: #6c6c6c;
  margin-right: 10px;
  margin-bottom: 5px;
  width: 100%;
}

.formJiraNewRadio {
  margin-left: 10px;
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  flex-direction: column;
}
.formJiraNewRadio_options {
  display: flex;
  margin-top: 10px;
  justify-content: flex-end;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1em;
}

.formJiraNewInputText,
.formJiraNewInputText_short,
.formJiraNewInputText_three {
  padding: 12px 0;
  outline: none;
  resize: none;
  padding: 0.625em;
  border-radius: 16px;
  height: 40px;
  background-color: #f2f4ff;
  border: 1px solid #f2f4ff;
  display: inline-block;
}
.formJiraNewInputText,
.formJiraNewInputText_short,
.formJiraNewInputText_three {
  width: 100%;
}

.formJiraNewRadioLabel {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}

.formJiraNewInputNumber {
  background-color: #f2f4ff;
  border: 1px solid #f2f4ff;
  border-radius: 16px;
  display: inline-block;
  height: 40px;
  outline: none;
  padding: 0.625em;
  resize: none;
  width: 30%;
}

.formJiraNewSelect {
  padding: 10px;
  border: 1px solid #d1d5e4;
  border-radius: 4px;
  width: 30%;
  /*max-width: 300px; */
  background: #fff;
  font-size: 16px;
}

.formNewArticleTextarea {
  outline: none;
  resize: none;
  height: 4em;
  padding: 1em;
  background-color: #f2f4ff;
  display: inline-block;
  width: calc(100% - 15px);
}

.formJiraNewCheckboxGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.formJiraNewCheckboxGroup_changes {
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.formJiraNewCheckboxLabel {
  display: flex;
  align-items: center;
  gap: 10px;
}
.formJiraNewRadioGroup {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.formJiraNewLabel__required {
  color: red;
  font-size: 12px;
}
.errorBorder {
  border: 2px solid #007bff;
}
.prefilledBorder {
  border: 2px solid var(--color-blue);
}

/* Autosuggest styles */
.autosuggest-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.react-autosuggest__input {
  padding: 12px 0;
  outline: none;
  resize: none;
  padding: 0.625em;
  border-radius: 16px;
  height: 40px;
  background-color: #f2f4ff;
  border: 1px solid #f2f4ff;
  display: inline-block;
}
.react-autosuggest__container {
  width: 80%;
}

.react-autosuggest__suggestions-container--open {
  position: absolute;
  background-color: white;
  border: 1px solid #ffffff;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
}
.employeeDetails {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-content: flex-start;
}

.react-autosuggest__suggestion {
  padding: 10px;
  cursor: pointer;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #007bff;
  color: white;
}

.formJiraNewCheckboxGroup_change {
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

/* Feedback Popup */
.feedbackPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1050;
}

.feedbackPopup__message {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}

.feedbackPopup__buttons {
  display: flex;
  justify-content: flex-end;
}

.feedbackPopup__button {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.feedbackPopup__button:hover {
  background-color: #0056b3;
}

.dislikePopup-content {
  width: 80%;
  max-width: 800px;
  padding: 20px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.formJiraNewButton_section {
  display: flex;
  gap: 2em;
  flex-direction: row-reverse;
}

.formJiraNewButton {
  border-radius: 100px;
  background: var(--color-blue);
  color: var(--color-white);
  padding: 10px 24px;
  border: none;
  width: max-content;
  cursor: pointer;
  text-align: center;
  width: -webkit-max-content;
  width: max-content;
}

.secondaryButton {
  background-color: var(--color-white);
  border: 1px solid var(--color-blue);
  color: var(--color-blue);
  cursor: pointer;
  border-radius: 100px;
  padding: 10px 24px;
  text-align: center;
  width: -webkit-max-content;
  width: max-content;
  margin-left: 10px;
}

.formJiraNewButton.enabled {
  background: var(--color-blue);
  color: var(--color-white);
  cursor: pointer;
  opacity: 100%;
}

.formJiraNewButton.disabled {
  background: var(--color-blue);
  color: var(--color-white);
  cursor: pointer;
  opacity: 70%;
  cursor: pointer;
}

.contactTextContainer {
  width: 100%;
  margin-top: 8em;
}

.contactTextContainer p a {
  color: var(--color-blue);
}
@media (max-width: 700px) {
  .formJiraNew__form {
    width: 80%;
  }
}

@media (max-width: 500px) {
  .formJiraNew__form {
    width: 100%;
  }
}
