.cluster-card-mobile {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .circles-container-card-mobile {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .circle-card-mobile {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #007bff;
    display: flex;
    align-items: center;
    font-size: 8px;
    justify-content: center;
  }
  
  .arrow-circle-card-mobile {
    margin-right: 1px !important;
    width: 25px !important;
  }
  