.formJira_update {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.formJira_update__title {
  color: var(--color-blue);
  font-size: 1.375em;
  margin-bottom: 1.5em;
}

.formJira_update__subtitle {
  color: var(--color-blue);
  font-size: 1em;
  font-weight: bold; /* Hace que el texto sea negrita */
  display: block; /* Asegura que esté en una línea */
}

.formJiraTable {
  width: 100%;
  border-collapse: collapse;
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 900px;
}

.formJiraTable__field {
  background-color: #b2c4f3;
  padding: 8px;
  text-align: left;
  width: 25%;
}

.formJiraTable__value {
  background-color: var(--color-lilac);
  padding: 8px;
  text-align: left;
  width: 25%;
}

.formJiraTable__checkbox {
  margin-left: 0;
}

.formJiraUpdateButton {
  border-radius: 100px;
  background: var(--color-blue);
  color: var(--color-white);
  padding: 10px 24px;
  border: none;
  width: max-content;
  cursor: pointer;
  text-align: center;
  display: inline-block; /* Para que se alinee bien */
}

.formJiraUpdateButtonContainer {
  text-align: right;
  padding-top: 2em;
}

.formJiraUpdate__section {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-top: 15px;
  height: auto;
}

.formJiraUpdate__section_manager,
.formJiraUpdate__section_viewnext {
  display: flex;
  align-items: center;
}

.formJiraUpdate__section_lce {
  display: inline-block;
}

.formJiraUpdate__uploadFiles--file {
  display: flex;
  align-items: center;
}

.formJiraUpdate__section__radio {
  padding-right: 1em;
}

.formJiraUpdate__section__actionTitle {
  padding-right: 0.5em;
}

.formJiraUpdateButton.enabled {
  background: var(--color-blue);
  color: var(--color-white);
  cursor: pointer;
  opacity: 100%;
}

.formJiraUpdateButton.disabled {
  background: var(--color-blue);
  color: var(--color-white);
  cursor: pointer;
  opacity: 70%;
}

.formJiraUpdate__uploadFiles {
  background-color: #f6f6f6;
  border: 1.5px solid var(--color-blue);
  padding: 0.5em;
  border-radius: 50%;
  line-height: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formJiraUpdate__radio {
  padding-right: 1em;
}

.formJiraUpdate__uploadFiles label {
  cursor: pointer;
}

.formJiraUpdate__uploadFiles--input {
  width: 0.1px;
  height: 0.1px;
  /* opacity: 0; */
  /* overflow: hidden; */
  /* position: absolute; */
  /* z-index: -1; */
}

.formJiraUpdate__section_emp {
  display: inline-block;
}

.formJiraUpdate__section_emp_yes {
  display: flex;
  margin: 5px 0;
  align-items: center;
}

.formJiraUpdate__section_emp_no {
  display: block;
  margin: 5px 0;
}

.formJiraUpdate__textarea {
  margin-top: 0.5em;
  width: 300px; /* Anchura de 300px */
  height: 100px; /* Altura de 100px */
  resize: none; /* Evita que el usuario cambie el tamaño del textarea */
  padding: 10px; /* Espacio interior para el texto */
  border-radius: 5px; /* Bordes redondeados */
  border: 1px solid var(--color-blue); /* Color de borde */
  font-size: 14px; /* Tamaño de la fuente */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Sombra para un mejor diseño */
  outline: none; /* Evita el borde azul al enfocar */
}

.pointer {
  cursor: pointer;
  font-weight: bold;
  width: fit-content;
}

.pointer:hover {
  color: var(--color-blue);
}
