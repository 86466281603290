.employeeChannel__searcherContainer {
  align-items: center;
  background-color: var(--color-white);
  border-radius: 2.5em;
  display: flex;
  width: 80%;
  align-items: center;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  position: relative;
}
.employeeChannel__searchInput {
  background-color: var(--color-white);
  border: none;
  border-radius: 3.25em;
  font-size: 12px;
  height: 100%;
  outline: none;
  width: 100%;
  padding: 0.75em;
}
.selected {
  background-color: #cfd2de;
}
.JiraAutocomplete__container {
  list-style: none;
  margin-top: 4px;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #f2f4fe;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #b8b9c2;
  margin-top: 0;
  padding: 0 !important;
}

.JiraAutocomplete__item {
  color: #031a34;
  padding: 1em;
  font-family: Telefonica Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.JiraAutocomplete__item:hover {
  background-color: #cfd2de;
  cursor: pointer;
}
.employeeChannel__infoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Jirasearch__articleList {
  padding: 2em 0;
}
.employeeChannel__firstSectionContainer {
  display: flex;
  gap: 10px;
}
.juridicaSelect {
  width: fit-content !important;
  font-size: 12px !important;
}
