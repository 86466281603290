/* Overlay styling */
.popup-container {
  position: relative;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Popup styling */
.new-feature-popup {
  background-color: white; 
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 400px;
  width: 90%;
  z-index: 1001; 
}

/* Header styling */
.new-feature-popup h2 {
  padding: 1.25em 1.875em 0;
  font-size: 1.75em;
  color: #333;
  border-radius: 10px 10px 0 0;
}

/* Paragraph styling */
.new-feature-popup p {
  padding: 1.25em 1.875em;
  display: flex;
  flex-direction: column;
  gap: 0.625em;
  color: #676d83;
  line-height: 160%;
}

/* Button styling */
.new-feature-popup button {
  background-color: #0066ff; 
  color: white;
  border: none;
  padding: 0.625em 0.938em;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  justify-content: right;
  float: right;
}

.new-feature-popup button:hover {
  background-color: #0056b3; 
}

/* Highlight section styling */
.highlight {
 
  text-align: center;
  position: absolute;
  display: inline-block;
  top: 18px;
  right: 90px;
  z-index: 1002; 
}

.folder-image {
  max-width: 100px;
  height: auto;
  z-index: 1;
  position: relative;
}

.highlight::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px; 
  height: 60px; 
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #0066ff;
  box-shadow: 
  inset 10px 25px #ffffff00,
  inset 0px 0px 30px #dadae3,
  inset 20px 0px 45px #e7e7e708, 
  0 0 20px #fff3, 
  0 0 30px #ffffff1c, 
  0 0 40px #fff;
  z-index: 0;
  pointer-events: none;
}