



.custom-modal .modal-content {
    border-radius: 20px;
    padding: 20px;
    border: none; 
}

.custom-modal .modal-header,
.custom-modal .modal-footer {
    border: none; 
}

.custom-title {
    font-size: 24px;
    font-family: Telefonica_Sans_Light;
    margin-bottom: 20px; 
}

.custom-label {
    color: #5f6368;
    font-size: 18px !important;
    margin-bottom: 0;
    font-family: Telefonica_Sans_Light;

}

.custom-input-role {
    border-radius: 12px !important;
    padding: 10px;
    font-size: 16px;
    background-color: #F0F0F0 !important;
    border: 1px solid #F0F0F0 !important;
    margin-left: 68px !important;
}
.custom-input-company {
    border-radius: 12px !important;
    padding: 10px;
    font-size: 16px;
    background-color: #F0F0F0 !important;
    border: 1px solid #F0F0F0 !important;
    margin-left: 26px !important;
}
.custom-input-from{
    border-radius: 12px !important;
    padding: 10px;
    font-size: 16px;
    background-color: #F0F0F0 !important;
    border: 1px solid #F0F0F0 !important;
    width: 92px !important;
    margin-left: 70px !important;
    
    

}

.custom-input-to{
    border-radius: 12px !important;
    padding: 10px;
    font-size: 16px;
    background-color: #F0F0F0 !important;
    border: 1px solid #F0F0F0 !important;
    width: 85px !important;
}

.custom-input:focus {
    border-color: #4a90e2;
    box-shadow: 0 0 5px rgba(74, 144, 226, 0.5);
}

.custom-button {
    padding: 10px 20px;
    border-radius: 15px;
    font-size: 16px;
}

.cancel-button {
    color: #007bff;
    border-color: #007bff;
}

.cancel-button:hover {
    background-color: #e8f0fe;
    color: #1a73e8;
}

.save-button {
    background-color: #007bff;
    border-color: #007bff;
}

.save-button:hover {
    background-color: #1764c0;
    border-color: #1764c0;
}

.custom-label-to{
    margin-left: 0px;
}

