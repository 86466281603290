.carousel-item img {
  height: fit-content;
  object-fit: contain;
  width: 100%;
  object-position: center;
  transition: transform 0.6s ease-in-out;
}
.carousel-indicators {
  list-style: none; /* Elimina los números o marcadores */
  padding: 0; /* Opcional: elimina el relleno */
  margin: 0; /* Opcional: elimina el margen */
}
