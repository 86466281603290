.btnInfo-exit, .btnInfo-download, .btnInfo-left, .btnInfo-right{
    cursor: pointer;
}
.employee-job,.talent-subtitle-mobile{
    font-family: Telefonica Sans !important;
}
.btnInfo-exit{
    width: 40px;
      height: 40px;
}
.btnInfo-download{
    width: 40px;
    height: 40px;
}

.btnInfo-exit, .Image-profile, .btnInfo-download, .btnInfo-left, .btnInfo-right {
    margin: 0;
    padding: 0;
  }
  
.custom-row, .col-auto, .col {
padding: 0;
margin: 0;
}

.row>* {
    padding-right: 0 !important;
}
.row{
--bs-gutter-x: 0 !important;
}
.summary-Tittle-mobile,.education-title-mobile,.experience-title-mobile{
    font-size:  1.5rem !important;
    font-family: Telefonica_Sans_Light;
}
.summary-Text-mobile{
    font-size: 1.15rem;
    font-family: Telefonica Sans;
    padding-right: 1rem !important;
    text-align: justify;
    
}

.carrer-table-mobile,.education-table-mobile{
    font-size: 1.04rem;
    font-family: Telefonica Sans;

}

.content-summary-experience-scroll{
    max-height: 1000px;
    overflow-y: auto;

    
    -ms-overflow-style: none;  
    scrollbar-width: none;  

    &::-webkit-scrollbar {
        display: none;
    }
}