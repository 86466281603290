.custom-bg {
    background-color: #F0F0F0;
  }
  
.ob-title,
.cluster-title {
  font-family: Telefonica Sans;
}
  

 /*  @media screen and (max-width: 700px) {
    .buttons {
      display: none;
    }
  
    .nav {
      display: none;
    }
  
    .mobile-nav {
      display: flex !important;
      justify-content: space-between !important;
      align-items: center !important;
      padding: 1em;
    }
  
    .dropdown-ob,
    .dropdown-cluster {
      height: 80px;
      position: relative;
      margin-bottom: 0.5em !important; 
      margin-right: 1rem !important;
    }
  
    .ob-title,
    .cluster-title {
      font-size: 1rem !important;
      margin-bottom: 0.2rem !important; 
    }
  
    .form-select {
      width: 100% !important; 
      height: 30px;
      font-size: 0.8rem !important;
    }
  
    .container-search {
      display: none !important;
    }
  }
  @media only screen and (min-width: 700px) and (max-width: 800px) {
    .mobile-nav {
      display: none !important;
    }
    .add-icon, .delete-icon {
      width: 40px !important;
      height: 40px !important;
      object-fit: cover !important;
      margin-left:-10px !important;
  
    }
    .dropdown {
      width: 160px !important;
      height: 150px !important;
    }
    .ob-title, .cluster-title {
      font-size: 1rem !important;
     
    }
    .form-select {
      width: 100% !important;
      height: 30px !important;
      font-size: 0.7rem !important;
    
    }
    .container-search {
      width: 400px !important;
      margin-left: 50px !important;
      margin-top: 20px;
    }
  }
  
  @media only screen and (min-width: 800px) and (max-width: 1080px) {
    .mobile-nav {
      display: none !important;
    }
    .add-icon, .delete-icon {
      width: 40px !important;
      height: 40px !important;
      object-fit: cover !important;
      margin-left:-40px !important;
     
    }
    .dropdown {
      width: 170px !important;
      height: 160px !important;
    }
    .ob-title, .cluster-title {
      font-size: 1.2rem !important;
     
    }
    .form-select {
      width: 100% !important;
      height: 30px !important;
      font-size: 0.8rem !important;
    
    }
    .container-search {
      width: 400px !important;
      margin-left: 80px !important;
      margin-top: 40px;
    }
  }
  
  @media only screen and (min-width: 1080px) and (max-width: 1500px) {
    .mobile-nav {
      display: none !important;
    }
  
    .container-search {
      width: 500px !important;
      margin-left: 80px !important;
    }
  } */
   
    
/* Para pantallas de 900px hacia abajo */
@media screen and (max-width: 900px) {
  /* .buttons {
    display: none;
  }

  .nav {
    display: none;
  } */

  .mobile-nav {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding: 1em;
  }

  .dropdown-ob,
  .dropdown-cluster {
    height: 80px;
    position: relative;
    margin-bottom: 0.5em !important; 
    margin-right: 1rem !important;
  }

  .ob-title,
  .cluster-title {
    font-size: 1rem !important;
    margin-bottom: 0.2rem !important; 
  }

  .form-select {
    width: 100% !important; 
    height: 30px;
    font-size: 0.8rem !important;
  }

}

/* Para pantallas entre 900px y 1080px */
/* @media only screen and (min-width: 900px) and (max-width: 1080px) {
  .mobile-nav {
    display: flex !important;
  }
  
  .add-icon, .delete-icon {
    width: 40px !important;
    height: 40px !important;
    object-fit: cover !important;
    margin-left: -40px !important;
  }
  
  .dropdown {
    width: 170px !important;
    height: 160px !important;
  }
  
  .ob-title, .cluster-title {
    font-size: 1.2rem !important;
  }
  
  .form-select {
    width: 100% !important;
    height: 30px !important;
    font-size: 0.8rem !important;
  }
  
  .container-search {
    width: 400px !important;
    margin-left: 80px !important;
    margin-top: 40px;
  }
} */

/* Para pantallas entre 1080px y 1500px */
/* @media only screen and (min-width: 1080px) and (max-width: 1500px) {
  .mobile-nav {
    display: flex !important;
  }

  .container-search {
    width: 500px !important;
    margin-left: 80px !important;
  }
} */