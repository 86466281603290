.search__articleList {
  padding: 5em 0;
}

.search__ArticleList--title {
  color: var(--color-blue);
  /* font-size: 1.688em; */
  font-size: 1.375em;
  margin-bottom: 1em;
}

.search__ArticleList--search {
  color: var(--color-black);
  font-size: 1em;
  font-weight: 600;
  margin-bottom: 2.5em;
}

.articleList__breadcrumb--container {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-bottom: 2.5em;
}

.articleList__breadcrumb--title {
  color: #6e7894;
  font-size: 0.875em;
  line-height: 1.25em;
  font-weight: 700;
}

.articleList__breadcrumb--text {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 0.875em;
}

.categorias__noResult {
  text-decoration: none;
  font-weight: 600;
  color: var(--color-blue);
}

.contacto__noResult {
  text-decoration: none;
  font-weight: 600;
  color: var(--color-blue);
}

/* Estilos cuando la sección no está expandida */
.articleList__content {
  gap: 1em;
  display: flex;
  flex-direction: column;
}

.articleItem {
  padding: 0.938em 1.375em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2em;
  border: 1px solid #d1d5e4;
}

.articleItem.expanded {
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
}

.articleItem__firstSection {
  cursor: pointer;
}

.articleItem__title {
  color: var(--color-blue);
  font-size: 0.875em;
  font-weight: 600;
}

.articleItem__plus {
  display: block;
}

.articleItem__plus.expanded {
  display: none;
}

.articleItem__minus {
  display: none;
}

.articleItem__minus.expanded {
  display: block;
}

.articleItem__firstSection {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.articleItem__firstSection.expanded {
  border-bottom: 1px solid #d1d5e4;
  padding: 0.938em 1.375em;
}

.answerBox {
  padding: 18px 24px;
  width: 100%;
  font-size: 0.875em;
  text-align: justify;
  line-height: 1.5;
}

.answerBox ul,
.answerBox ol {
  padding-left: 1.125em;
}

.answerBox img {
  padding: 18px 24px;
  max-width: 100%;
}

.answerBox a,
.answerBox u {
  color: var(--color-blue);
  text-decoration: none;
}

.answerBox strong {
  font-weight: 600;
}

.footerBox {
  display: flex;
  flex-direction: row;
  padding: 18px 24px;
  width: 100%;
  justify-content: space-between;
  background-color: #f2f4ff;
}

.articleList--article__useful {
  color: #414b61;
  font-size: 1em;
}

.iconBox {
  display: flex;
  gap: 32px;
}

.subcategories__articleList--article__dislike {
  transform: rotate(180deg);
}

.search__articleList__content {
  gap: 1em;
  display: flex;
  flex-direction: column;
}