.table-container-details {
  width: 140%;
  display: flex;
  margin-left: -160px;
  margin-top: 10px;
  /*margin: 20px auto;*/
}
.ticket-details-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  /*  max-width: 800px; */
}

 
.tickets-table{
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-size: 14px;
}

.tickets-table th,
.tickets-table td {
  border-bottom: 1px solid #ddd;
  padding: 10px;
}

.tickets-table th {
  background-color: #f2f2f2;
}

.tickets-table tr:hover {
  background-color: #f1f1f1;
}
.tickets-table td p {
  margin: 0;
  padding: 0;
  display: block;
}
.especialTD-details {
  background-color: #f9f9f9;
  font-weight: 700;
  grid-column: span 9;
  padding: 10px;
  text-align: left;
  width: 100%;
  margin-bottom: 70px;
}

/* Prioridad (Pr) */
.priority-high {
  background-color: red;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.priority-medium {
  background-color: orange;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.ticket-button-queue,
.ticket-button-responsible,
.ticket-button-state,
.ticket-button-priority {
  display: flex;
  flex-direction: column;
}
.edit-pencil-button {
  background: none;
  border: none;
  cursor: pointer;
}
.img-edit-pencil {
  width: 20px;
  height: 20px;
}

.ticket-details-content-details {
  margin-left: 100px;
}

/* Alinea el botón de edición a la derecha */
.edit-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #007bff;
}
.ticket-detail-answer-tittle {
  color: #0066ff;
  font-size: 1.5em;
  font-weight: 300;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

/* Estilos del popup */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
}

.edit-field {
  margin-bottom: 15px;
}

.popup-buttons {
  display: flex;
  justify-content: space-between;
}

.popup-buttons button {
  background-color: #0066ff;
  color: white;
  border: none;
  padding: 0.625em 0.938em;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  justify-content: right;
  float: right;
}
.popup-buttons button:hover {
  background-color: #0056b3;
}

.ticket-detail-answer {
  display: flex;
  justify-content: space-evenly;
  gap: 1em;
}
.ticket-detail-answer-email {
  width: 60%;
}
.form-container {
  background-color: #f3f3f3;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}
.ticket-detail-answer-inbenta {
  padding: 20px;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 40%;
}

.form-container h2 {
  font-size: 18px;
  color: #333;
}

.form-container label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;
}

.form-container input[type='email'],
.form-container textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 14px;
  color: #555;
}

.form-container input:focus,
.form-container textarea:focus {
  outline: none;
  border-color: #0078d4;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.form-container button {
  background-color: #0066ff;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-container button:hover {
  background-color: #005a9e;
}

.form-container .clear-button {
  background: none;
  border: none;
  color: #d83b01;
  cursor: pointer;
  font-size: 16px;
}
