.experience-container{
    width: auto;
    height: auto;
}

/*Tables*/
.scroll-container-experience {
    max-height: 325px; 
}
.scroll-container-education {
    max-height: 250px; 
}

.scroll-container-experience, .scroll-container-education {
    overflow-y: auto; 

    -ms-overflow-style: none; 
    scrollbar-width: none;  

    &::-webkit-scrollbar {
        display: none;
    }
}
.carrer-table{
    margin-top: 0.5rem;
    font-size: 0.9rem;
}
.education-table{
    margin-top: 0.5rem;
    font-size: 0.9rem;
}
.experience-title,.education-title,.add-cluster{
    font-size: 1.45rem !important;
    font-family: Telefonica_Sans_Light !important;
}

.icon-Add{
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.cluster-exp{
    cursor: pointer;
}

@media only screen and (min-width: 900px) and (max-width: 1025px) {
    .experience-container {
        /* margin-top: 1px !important; */
        height: 100vh;
        max-height: 100vh;
    }
    /*CEO Pool*/
    .svg-Style{
        width: 160px;
        height: 50px;
        margin-top: 30px;
        margin-left: -25px;
    }
    /*CLUSTER STYLE*/
    .svg-Style-cluster{
        width: 160px;
        height: 50px;
        margin-top: 30px;
        margin-left: 9.3px;
    }
    .carrer-table{
        margin-top: 0.5rem;
        font-size: 0.68rem;
    }
    .education-table{
        margin-top: 0.5rem;
        font-size: 0.68rem;
    }
     /*Tables*/
     .scroll-container-experience {
        max-height: 200px; 
    }
    .scroll-container-education {
        max-height: 180px; 
    }
    .experience-container .experience-title
    {
        font-size: 1.1rem !important;
        margin-top: 1.5rem !important;
    } 
    .experience-container .education-title,.experience-container .add-cluster {
        margin-top: 1rem !important;
        font-size: 1.1rem !important;
    }
}

/* Para pantallas grandes (laptops y pantallas medianas) - MI LAPTOP*/
@media only screen and (min-width: 1025px) and (max-width: 1599px) {
    .experience-container {
        height: 100vh;
        max-height: 100vh;
    }
    /*CEO Pool*/
    .svg-Style{
        width: 160px;
        height: 50px;
        margin-left: 0px;
    }
    /*CLUSTER STYLE*/
    .svg-Style-cluster{
        width: 160px;
        height: 50px;
        margin-left: 20px;
    }
    .carrer-table{
        margin-top: 0.5rem;
        font-size: 0.85rem;
    }
    .education-table{
        margin-top: 0.5rem;
        font-size: 0.85rem;
    }
    /*Tables*/
    .scroll-container-experience {
        max-height: 160px; 
    }
    .scroll-container-education {
        max-height: 160px; 
    }
    

    .experience-container .experience-title, .experience-container .education-title, .experience-container .add-cluster{
        font-size: 1.1rem !important;
    }
    .experience-container .experience-title{
        margin-top: 10px !important;
    }
    .icon-Add{
        width: 17px;
        height: 17px;
    }


}

@media only screen and (min-width: 1600px) and (max-width: 2560px) {
    .experience-container {
        height: 100vh;
        max-height: 100vh;
        margin-top: 22.5px;
    }
   
    .carrer-table{
        margin-top: 0.5rem;
        font-size: 1.2rem;
        font-family: Telefonica Sans;
    }
    .education-table{
        margin-top: 0.5rem;
        font-size: 1.2rem;
        font-family: Telefonica Sans;

    }
    .experience-title{
        margin-top: 1.92rem !important;
        font-size: 1.5rem !important; 
        font-family: Telefonica_Sans_Light;
    }
    .education-title{
        font-size: 1.5rem !important;
        font-family: Telefonica_Sans_Light;
    }
    .add-cluster{
        font-size: 1.5rem !important;
        font-family: Telefonica_Sans_Light;
    }

}