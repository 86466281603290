
.tittleHeader-mobile{
  font-family: Telefonica Sans; 
  color:#06f;
}
.talent-title-mobile{
  font-family: Telefonica Sans;
}
.hub-title-mobile{
  font-family: Telefonica_Sans_Light;;

}

.btnHeader-exit-mobile{
  width: 40px !important;
  height: 40px !important;
  cursor: pointer;
}
.logo-telefonica-mobile{
  width: 5rem !important;
}
  
/* Media queries para pantallas pequeñas (móviles) */
/* @media screen and (max-width: 700px) {
  .tittleHeader {
    font-size: 1.2rem; 
  }

  .talent-title {
    font-size: 1.8rem; 
  }
  .hub-title {
    font-size: 1.5rem;
  }

  .logo-web {
    display: none;
    width: 20px !important;
    height: 20px !important;
  }

  .btn {
    font-size: 0.875rem; 
    line-height: 1.5; 
  }
}

@media only screen and (min-width: 700px) and (max-width: 1025px) {
  .tittleHeader {
    font-size: 1.4rem; 
    margin-top: 30px;
  }

  .talent-title {
    font-size: 2rem; 
  }
  .hub-title {
    font-size: 1.7rem;
  }

  .logo-web {
    margin-top: -150px;
    width: 20px !important;
    height: 20px !important;
  }

  .btn {
    font-size: 0.875rem; 
    line-height: 1.5; 
    margin-top: 30px;
  }
}
  */
 