.itemList__icon {
  height: 15px;
  width: 15px;
  cursor: pointer;
}
.verpopup {
  display: flex;
  flex-direction: column;
}
.catList__verPopup--imgContainer {
  height: 150px;
  width: 150px;
}
.popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 80%;
  max-height: 70%;
  overflow: auto;
}

.popup-overlay {
  background-color: none;
  position: initial;
}

.verpopup {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em 2em;
  /* gap: 2em; */
  background-color: white;
  border-radius: 20px;
}
.catPopup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.catPopup__infoContainer {
  display: flex;
  flex-direction: column;
  gap: 2em;
  align-items: center;
  width: 70%;
}
