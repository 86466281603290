  .cluster {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items: center;
  }
  
  .svg-Style {
    margin: 10px;
  }
  
  .rect-style {
    fill: #007bff;
    stroke: #ffffff;
  }
  
  .position-SVG {
    font-size: 14px;
    text-anchor: middle;
    dominant-baseline: middle;
    fill: #ffffff;
  }
  
  .circles-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .circle {
    width: 78px;
    height: 78px;
    border-radius: 50%;
    background-color: #007bff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    font-size: 14px;
    font-weight: bold;
  }
  
  .arrow-circle{
    margin-right: 1px !important;
    width: 25px !important;
  }
  .arrow-position{
    transform: translate(17px,7px);
  }

@media only screen and (min-width: 900px) and (max-width: 1025px) {
  .circles-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap; 
    width: 100%; 
    max-width: 100%;
    margin-bottom: 0rem !important;
    
   
  }
  .circles-container .circle{
    width: 40px !important;
    height: 40px !important;
    font-size: 10px !important;
    padding-right: 5px;
    margin-right: 5px !important;
   
  }
  .arrow-circle{
    /* margin-right: 1px !important; */
    width: 22px !important;
  }

}
  
@media only screen and (min-width: 1025px) and (max-width: 1599px) {
  .cluster{
    margin-top: 25px !important;

  }
  .circles-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap; 
    width: 100%; 
    max-width: 100%;
  
  }
  .circles-container .circle{
    margin-top: 0px !important;
    width: 45px !important;
    height: 45px !important;
    font-size: 12px !important;
    padding-right: 5px;
    margin-right: 10px !important;
   
  }
  .arrow-circle{
    margin-right: 1px !important;
    width: 30px !important;
  }
 

}

@media only screen and (min-width: 1599px) and (max-width: 2560px) {
  .cluster{
    margin-top: 10px !important;

  }
  .circles-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap; 
    width: 100%; 
    max-width: 100%;
  }
  .circles-container .circle{
    margin-top: 0px !important;
    width: 60px !important;
    height: 60px !important;
    font-size: 12px !important;
    padding-right: 5px;
   
  }
  .arrow-circle{
    margin-right: 1px !important;
    width: 30px !important;
  }

}

